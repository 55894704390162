import React, { useCallback } from "react";
import { Card } from "antd";
import { CardTestItem } from "../../pages";
import { TitleCard } from "./TitleCard";
import { Colors } from "../../constants";
import { IQuestion } from "../Question/types";
import { EditQuestionCard } from "./EditQuestionCard";
import { ConstructorMenu } from "./ConstructorMenu";
import { PauseOutlined } from "@ant-design/icons";
import { noop } from "../../utils";
import styled from "styled-components";
import { useDrag, useDrop } from "react-dnd";

interface QuestionCardProps {
  type: CardType;
  testData: CardTestItem;
  onClick: () => void;
  isActive: boolean;
  question?: IQuestion;
  testQuestionLoading: boolean;
  moveCard: (cardId: number, targetIdx: number) => void;
  findCard: (cardId: number) => { card: IQuestion; index: number };
  cardOrder: IQuestion[];
}

export enum CardType {
  TITLE = "TITLE",
  QUESTION = "QUESTION",
}

export const QuestionCard = ({
  type,
  onClick,
  testData,
  isActive,
  question,
  testQuestionLoading,
  moveCard,
  findCard,
  cardOrder,
}: QuestionCardProps): JSX.Element => {
  const onCardClick = useCallback(() => {
    onClick();
  }, [onClick]);

  const isActiveStyles = isActive ? "border-r-8" : "";
  const originalIndex = findCard(question?.id ?? -1).index;
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type,
      item: { id: question?.id ?? 0, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(droppedId, originalIndex);
        }
        if (didDrop) {
          onClick();
        }
      },
    }),
    [originalIndex, moveCard]
  );
  const opacity = isDragging ? 0.3 : 1;
  const [, drop] = useDrop(
    () => ({
      accept: CardType.QUESTION,
      hover({ id: draggedId }: any) {
        if (question?.id != null && draggedId !== question?.id) {
          const { index: overIndex } = findCard(question?.id ?? -1);
          moveCard(draggedId, overIndex);
        }
      },
    }),
    [findCard, moveCard, onClick]
  );

  return (
    <div className='flex w-full my-4'>
      <ConstructorMenu
        isActive={isActive}
        cardOrder={cardOrder}
        question={question}
      />
      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
      <div
        className='w-full'
        onClick={onCardClick}
        onKeyDown={noop}
        role='button'
        style={{
          opacity,
        }}
        ref={(node) => {
          if (question) {
            preview(node);
          }
        }}
      >
        {type === CardType.QUESTION && (
          <DragWrapper
            className={`text-opacity-0 flex justify-center hover:text-opacity-100  rounded-t-lg ${isActiveStyles}`}
            ref={(node) => drag(drop(node))}
          >
            <IconWrapper isActive={isActive} className='flex justify-center'>
              <StyledPauseOutlined />
            </IconWrapper>
          </DragWrapper>
        )}
        <Card
          className={`w-full rounded-b-lg cursor-pointer shadow-md hover:shadow-xl border-0 ${isActiveStyles}`}
          onClick={onCardClick}
          style={{
            borderColor: Colors.blue_8,
          }}
          loading={testQuestionLoading}
        >
          {type === CardType.TITLE ? (
            <TitleCard isActive={isActive} testData={testData} />
          ) : (
            <EditQuestionCard question={question} isActive={isActive} />
          )}
        </Card>
      </div>
    </div>
  );
};

interface PauseOutlinedProps {
  readonly isActive: boolean;
}

const StyledPauseOutlined = styled(PauseOutlined)`
  rotate: 90deg;
  color: ${Colors.gray_7};
  vertical-align: 0.125rem;
`;

const IconWrapper = styled.div<PauseOutlinedProps>`
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  width: 100%;
  cursor: move;

  &:hover {
    opacity: 1;
  }
`;

const DragWrapper = styled.div`
  width: 100%;
  height: 24px;
  background-color: white;
  border-color: ${Colors.blue_8};
`;
