import React, { useCallback, useEffect, useState } from "react";
import { Form, Input, Checkbox, Button, message, List, Card } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { FileWordOutlined, FileOutlined } from "@ant-design/icons/lib/icons";
import axios from "axios";
import {
  Routes,
  Colors,
  getDownloadTestDataAPI,
  getDownloadTestJSON,
  getDownloadTestDOCX,
} from "../../constants";
import { CardTestItem, StyledContent } from "../../pages";
import { getBaseUrl } from "../../utils/getEnv";
import { useHistory } from "react-router-dom";
import { antIconStyles } from "../../utils";

interface SettingsProps {
  data?: CardTestItem;
  isLoading: boolean;
}

interface Interpretation {
  slug: string;
  name: string;
  description: string;
}

export const Settings = ({ data }: SettingsProps): JSX.Element => {
  const history = useHistory();
  const [randomOrder, setRandomOrder] = useState(data?.random_order);
  const [isActive, setIsActive] = useState(data?.isActive);
  const [isPublic, setIsPublic] = useState(data?.public);

  const testUrl = String(
    window.location.host + Routes.START_TEST_PREFIX + data?.hash
  );
  const onFinishCopyLink = () => {
    window.navigator.clipboard
      .writeText(testUrl)
      .then(() => {
        message.success("Ссылка скопирована!");
      })
      .catch(() => {
        message.error("Не удалось скопировать ссылку!");
      });
  };

  const onFinishFailedCopyLink = () => {
    message.error("Не удалось скопировать ссылку!");
  };

  const user_token = localStorage.getItem("access-token");

  const [questionInterpretationList, setQuestionInterpretationList] = useState<
    Array<Interpretation>
  >([]);
  const [questionInterpretation, setQuestionInterpretation] = useState(
    data?.interpretation_type
  );

  useEffect(() => {
    axios
      .get(`${getBaseUrl()}/v1.0/tests/interpretations/`)
      .then((data) => {
        setQuestionInterpretationList(data.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, []);

  const onSubmit = useCallback(() => {
    axios
      .put(
        `${getBaseUrl()}/v1.0/tests/` + data?.hash + "/settings/",
        {
          random_order: randomOrder,
          isActive: isActive,
          public: isPublic,
          interpretation_type: questionInterpretation,
        },
        { headers: { Authorization: "Token " + user_token } }
      )
      .then((data) => {
        if (data.data.success) {
          message.success("настройки сохранены");
        }
      });
  }, [
    questionInterpretation,
    randomOrder,
    isActive,
    isPublic,
    user_token,
    data,
  ]);

  const DeleteTest = useCallback(() => {
    axios
      .delete(`${getBaseUrl()}/v1.0/tests/` + data?.hash + "/", {
        headers: { Authorization: "Token " + user_token },
      })
      .then((data) => {
        console.log(data);
        if (data.status === 204) {
          message.success("Тест удален");
          history.push("/main/");
        }
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, [data, user_token, history]);

  const UploadJSON = useCallback((element, e) => {
    window.open(
      `${getBaseUrl()}` + Routes.API_VERSION + getDownloadTestJSON(element.hash)
    );
  }, []);

  const UploadDOCX = useCallback((element, e) => {
    window.open(
      `${getBaseUrl()}` + Routes.API_VERSION + getDownloadTestDOCX(element.hash)
    );
  }, []);

  const onChangePublic = (e: CheckboxChangeEvent) => {
    setIsPublic(!isPublic);
  };
  const onChangeActive = (e: CheckboxChangeEvent) => {
    setIsActive(!isActive);
  };
  const onChangeRandom = (e: CheckboxChangeEvent) => {
    setRandomOrder(!randomOrder);
  };

  const public_label = "Публичный";
  const active_label = "Активный";
  const random_label = "Случайный порядок вопросов";

  return (
    <StyledContent>
      <div
        className='flex justify-center'
        style={{ padding: 24, paddingTop: 0 }}
      >
        <div className={"w-1/2"}>
          <Card
            title={"Ссылка на тест"}
            className={"rounded-lg cursor-pointer shadow-md hover:shadow-xl"}
            style={{
              width: "100%",
              borderColor: Colors.blue_8,
              marginTop: "24px",
            }}
          >
            <Form
              onFinish={onFinishCopyLink}
              onFinishFailed={onFinishFailedCopyLink}
              autoComplete='off'
            >
              <Form.Item name='url' label='URL'>
                <Input
                  type='text'
                  onClick={onFinishCopyLink}
                  defaultValue={testUrl}
                  readOnly={true}
                />
              </Form.Item>
              <Form.Item>
                <Button type='primary' htmlType='submit'>
                  Скопировать ссылку
                </Button>
              </Form.Item>
            </Form>
          </Card>
          <Form onFinish={onSubmit}>
            <Card
              title={"Настройки теста"}
              className={"rounded-lg cursor-pointer shadow-md hover:shadow-xl"}
              style={{
                width: "100%",
                borderColor: Colors.blue_8,
                marginTop: "24px",
              }}
            >
              <Form.Item name='public'>
                <Checkbox defaultChecked={isPublic} onChange={onChangePublic}>
                  {" "}
                  {public_label}{" "}
                </Checkbox>
              </Form.Item>
              <Form.Item name='active'>
                <Checkbox defaultChecked={isActive} onChange={onChangeActive}>
                  {" "}
                  {active_label}{" "}
                </Checkbox>
              </Form.Item>
              <Form.Item name='random'>
                <Checkbox
                  defaultChecked={randomOrder}
                  onChange={onChangeRandom}
                >
                  {" "}
                  {random_label}{" "}
                </Checkbox>
              </Form.Item>
            </Card>
            <Form.Item>
              <List itemLayout='horizontal'>
                <List.Item>
                  <Card
                    title={"Без интерпретации"}
                    className={
                      questionInterpretation === null
                        ? "rounded-lg cursor-pointer shadow-md hover:shadow-xl border-b-8"
                        : "rounded-lg cursor-pointer shadow-md hover:shadow-xl border-0"
                    }
                    style={{ width: "100%", borderColor: Colors.blue_8 }}
                    onClick={() => setQuestionInterpretation(null)}
                  >
                    Интерпретация не требуется
                  </Card>
                </List.Item>
                {questionInterpretationList.map((interpretation, idx) => (
                  <List.Item key={idx}>
                    <Card
                      title={interpretation.name}
                      className={
                        questionInterpretation === interpretation.slug
                          ? "rounded-lg cursor-pointer shadow-md hover:shadow-xl border-b-8"
                          : "rounded-lg cursor-pointer shadow-md hover:shadow-xl border-0"
                      }
                      style={{ width: "100%", borderColor: Colors.blue_8 }}
                      onClick={() =>
                        setQuestionInterpretation(interpretation.slug)
                      }
                    >
                      {interpretation.description}
                    </Card>
                  </List.Item>
                ))}
              </List>
            </Form.Item>
            <Card
              title={"Действия с тестом"}
              className={"rounded-lg cursor-pointer shadow-md hover:shadow-xl"}
              style={{
                width: "100%",
                borderColor: Colors.blue_8,
                marginTop: "24px",
                marginBottom: "24px",
              }}
            >
              <Button
                style={{ width: "45%", marginBottom: "12px" }}
                onClick={() => DeleteTest()}
                danger
              >
                Удалить тест
              </Button>
              <div>
                <Button
                  className={"inline"}
                  style={{ width: "45%" }}
                  icon={<FileOutlined style={antIconStyles} />}
                  onClick={(e) => UploadJSON(data, e)}
                >
                  Скачать тест в формате .json
                </Button>
                <Button
                  className={"inline"}
                  style={{ width: "45%", marginLeft: "10%" }}
                  icon={<FileWordOutlined style={antIconStyles} />}
                  onClick={(e) => UploadDOCX(data, e)}
                >
                  Скачать тест в формате .docx
                </Button>
              </div>
            </Card>
            <Form.Item>
              <Button type='primary' htmlType='submit'>
                Сохранить
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </StyledContent>
  );
};
