import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
  getNumberTypeByQuestionType,
  getQuestionTypeByNumberType,
  IQuestion,
} from "../Question/types";
import { StyledQuestionNameInput } from "./styles";
import { useMutation } from "react-query";
import { EditQuestionTypeSelect } from "./EditQuestionTypeSelect";
import { Divider } from "antd";
import { CardFooter } from "./CardFooter";
import { RequiredQuestionSelect } from "./RequiredQuestionSelect";
import { Answers } from "./Answers";
import { editTestQuestionRequest } from "../../api/test";
import { invalidateTestQuestionRequest } from "../../utils/invalidation";
import { useRecoilValue } from "recoil";
import { currentTestHash } from "../../recoil/selectors";
import { debounce } from "lodash";
import { renderQuestionName } from "../../utils/renderQuestionName";

interface EditQuestionCardProps {
  question?: IQuestion;
  isActive: boolean;
}

export const EditQuestionCard = ({
  question,
  isActive,
}: EditQuestionCardProps): JSX.Element => {
  const testHash = useRecoilValue(currentTestHash);
  const [isChangeCorrectAnswers, setIsChangeCorrectAnswers] =
    useState<boolean>(false);

  const questionName = useMemo(() => renderQuestionName(question), [question]);

  const updateQuestionMutation = useMutation(async (newData: IQuestion) => {
    return editTestQuestionRequest(newData, testHash).then(() => {
      invalidateTestQuestionRequest(testHash);
    });
  });

  const debouncedNameUpdate = useRef(
    debounce(async (newName) => {
      if (!question) {
        return;
      }
      const newQuestion: IQuestion = {
        ...question,
        name: newName,
      };
      updateQuestionMutation.mutate(newQuestion);
    }, 500)
  ).current;

  const onNameChange = useCallback(
    (e) => {
      debouncedNameUpdate(e.target.value);
    },
    [debouncedNameUpdate]
  );

  useEffect(() => {
    return () => {
      debouncedNameUpdate.cancel();
    };
  }, [debouncedNameUpdate]);

  const onChangeCorrectAnswers = useCallback(() => {
    setIsChangeCorrectAnswers(!isChangeCorrectAnswers);
  }, [isChangeCorrectAnswers]);

  if (!question) {
    return <></>;
  }

  return (
    <>
      {isActive ? (
        <div className='flex mb-3'>
          <StyledQuestionNameInput
            onChange={onNameChange}
            defaultValue={question.name}
            size='large'
            maxLength={1500}
            autoSize
            bordered={false}
          />
          <EditQuestionTypeSelect
            type={getQuestionTypeByNumberType(question.type)}
            updateQType={(type) => {
              const newQuestion: IQuestion = {
                ...question,
                type: getNumberTypeByQuestionType(type),
              };
              updateQuestionMutation.mutate(newQuestion);
            }}
          />
        </div>
      ) : (
        <>
          {questionName}
        </>
      )}
      <Answers
        isActive={isActive}
        question={question}
        isChangeCorrectAnswers={isChangeCorrectAnswers}
      />
      {isActive && <Divider style={{ margin: 0 }} />}
      {isActive && (
        <>
          <RequiredQuestionSelect question={question} />
          <CardFooter
            questionId={question.id}
            onChangeCorrectAnswers={onChangeCorrectAnswers}
            isChangeCorrectAnswers={isChangeCorrectAnswers}
          />
        </>
      )}
    </>
  );
};
