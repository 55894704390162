import React from "react";
import { StyledInput } from "./styles";
import { useMutation } from "react-query";
import { useRecoilValue } from "recoil";
import { currentTestHash } from "../../recoil/selectors";
import { createTestQuestionAnswerRequest } from "../../api/test";
import { invalidateTestQuestionRequest } from "../../utils/invalidation";
import { isSuccessfulAndWithData } from "../../api/api";

interface AddNewAnswerInputProps {
  questionId: number;
  type: number;
  nextAnswerNumber: number;
  onCreatedAnswer: (value: number) => void;
  createCorrect?: boolean;
}

export const AddNewAnswerInput = ({
  questionId,
  type,
  nextAnswerNumber,
  onCreatedAnswer,
  createCorrect = false,
}: AddNewAnswerInputProps): JSX.Element => {
  const testHash = useRecoilValue(currentTestHash);

  const createAnswerMutation = useMutation(async () => {
    const newAnswer = {
      value: `Вариант ${nextAnswerNumber + 1}`,
      type: type,
      is_correct: createCorrect,
      question: questionId,
    };
    return createTestQuestionAnswerRequest(newAnswer, testHash)
      .then((response) => {
        if (isSuccessfulAndWithData(response) && response.data?.id) {
          onCreatedAnswer(response.data.id);
        }
      })
      .then(() => {
        invalidateTestQuestionRequest(testHash);
      })
      .catch((err) => console.log(err));
  });

  return (
    <StyledInput
      placeholder={"Добавить вариант"}
      bordered={false}
      onClick={() => {
        createAnswerMutation.mutate();
      }}
    />
  );
};
