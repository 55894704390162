import React, { useCallback, useState } from "react";
import { Switch } from "antd";
import { IQuestion } from "../Question/types";
import { useRecoilValue } from "recoil";
import { currentTestHash } from "../../recoil/selectors";
import { useMutation } from "react-query";
import { editTestQuestionRequest } from "../../api/test";
import { invalidateTestQuestionRequest } from "../../utils/invalidation";

interface RequiredQuestionSelectProps {
  question: IQuestion;
}

export const RequiredQuestionSelect = ({
  question,
}: RequiredQuestionSelectProps): JSX.Element => {
  const testHash = useRecoilValue(currentTestHash);
  const [requiredStatus, setRequiredStatus] = useState<boolean>(
    question?.is_required ?? false
  );

  const updateRequiredStatusMutation = useMutation(
    async (newData: IQuestion) => {
      return editTestQuestionRequest(newData, testHash).then(() => {
        invalidateTestQuestionRequest(testHash);
      });
    }
  );
  const updateIsRequiredStatus = useCallback((newStatus: boolean) => {
    if (!question) {
      return;
    }
    const newQuestion: IQuestion = {
      ...question,
      is_required: newStatus,
    };
    updateRequiredStatusMutation.mutate(newQuestion);
  }, [question, updateRequiredStatusMutation]);

  const onRequiredStatus = useCallback(
    (Status) => {
      setRequiredStatus(Status);
      updateIsRequiredStatus(Status);
    },
    [setRequiredStatus, updateIsRequiredStatus]
  );

  return (
    <div>
      <div>
        <Switch defaultChecked={requiredStatus} onChange={onRequiredStatus} />
        Обязательный вопрос
      </div>
    </div>
  );
};
