import React from "react";
import { StatisticsCard } from "./StatisticsCard";

export const UserSection = (): JSX.Element => {
  return (
    <>
      <StatisticsCard
        cardTitle={"Статистика пользователя"}
        bodyStyle={{ textAlign: "center" }}
      ></StatisticsCard>
    </>
  );
};
