import React, { MutableRefObject } from "react";
import { EditAnswerInput } from "../EditAnswerInput";
import { AnswerResponse } from "../../Answer/types";

interface TextAnswerInputProps {
  answer: AnswerResponse;
  inputRef: MutableRefObject<any> | null;
}

export const TextAnswerInput = ({
  answer,
  inputRef,
}: TextAnswerInputProps): JSX.Element => {
  return (
    <div className='flex flex-grow'>
      <EditAnswerInput answerResponse={answer} inputRef={inputRef} />
    </div>
  );
};
