import React from "react";
import { useLocation } from "react-router-dom";
import { TABS_KEYS } from "../Constructor/ConstructorBase";
import { ANSWERS_TABS_KEYS } from "./NavigationStatisticsCard";
import { TestSection } from "./TestSection";
import { QuestionSection } from "./QuestionSection";
import { UserSection } from "./UserSection";

export const NavigationStatisticsRouterCard = (): JSX.Element => {
  const location = useLocation();
  return (
    <>
      {location.hash === TABS_KEYS.ANSWERS && <TestSection />}
      {location.hash.includes(ANSWERS_TABS_KEYS.QUESTION) && (
        <QuestionSection />
      )}
      {location.hash === ANSWERS_TABS_KEYS.USER && <UserSection />}
    </>
  );
};
