import React from "react";
import { Colors } from "../../constants";
import Title from "antd/lib/typography/Title";

interface TabTitleProps {
  text: string;
  isActiveTab: boolean;
}

export const TabTitle = ({ text, isActiveTab }: TabTitleProps): JSX.Element => {
  return (
    <Title
      level={3}
      style={{
        color: isActiveTab ? Colors.blue_8 : Colors.gray_10,
        marginBottom: "unset",
      }}
    >
      {text}
    </Title>
  );
};
