import React from "react";
import { PageHeader as AntPageHeader } from "antd";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";

interface PageHeaderProps {
  routes?: Route[];
  children?: React.ReactNode;
  title: string;
  isLoading: boolean;
}

export const PageHeader = ({
  children,
  title,
}: PageHeaderProps): JSX.Element => {
  return (
    <>
      <AntPageHeader style={{ backgroundColor: "white", padding: 0 }}>
        <p
          className='font-semibold text-xl'
          style={{ paddingLeft: 24, paddingTop: 12 }}
        >
          {title}
        </p>
        {children}
      </AntPageHeader>
    </>
  );
};
