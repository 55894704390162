import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { useLocation } from "react-router-dom";
import { CardTestItem } from "../../pages";
import { PageHeader } from "../PageHeader";
import { HashLink as Link } from "react-router-hash-link";
import { Constructor } from "./Constructor";
import { useTranslation } from "react-i18next";
import { Answers } from "./Answers";
import { Settings } from "./Settings";
import { ANSWERS_TABS_KEYS } from "../Statistics/NavigationStatisticsCard";

const { TabPane } = Tabs;

interface ConstructorBaseProps {
  testData: CardTestItem;
  isLoading: boolean;
}

export enum TABS_KEYS {
  CONSTRUCTOR = "constructor",
  ANSWERS = "#responses",
  SETTINGS = "#settings",
}

export const ConstructorBase = ({
  isLoading,
  testData,
}: ConstructorBaseProps): JSX.Element => {
  const location = useLocation();

  const [activeKey, setActiveKey] = useState<TABS_KEYS>(TABS_KEYS.CONSTRUCTOR);

  useEffect(() => {
    if (location.hash === "") {
      setActiveKey(TABS_KEYS.CONSTRUCTOR);
    } else if (
      location.hash === TABS_KEYS.ANSWERS ||
      location.hash.includes(ANSWERS_TABS_KEYS.TEST) ||
      location.hash.includes(ANSWERS_TABS_KEYS.USER) ||
      location.hash.includes(ANSWERS_TABS_KEYS.QUESTION)
    ) {
      setActiveKey(TABS_KEYS.ANSWERS);
    } else {
      setActiveKey(TABS_KEYS.SETTINGS);
    }
  }, [location]);
  const { t } = useTranslation();

  return (
    <PageHeader title={t("edit_test_header")} isLoading={isLoading}>
      <Tabs
        activeKey={activeKey}
        centered
        tabBarStyle={{ margin: 0, height: 48 }}
        size={"large"}
      >
        <TabPane
          tab={
            <Link to='#' title=''>
              {t("questions")}
            </Link>
          }
          key='constructor'
        >
          <Constructor isLoading={isLoading} testData={testData} />
        </TabPane>
        <TabPane
          tab={<Link to={TABS_KEYS.ANSWERS}> {t("answers")}</Link>}
          key={TABS_KEYS.ANSWERS}
        >
          <Answers isLoading={isLoading} testData={testData} />
        </TabPane>
        <TabPane
          tab={<Link to={TABS_KEYS.SETTINGS}> {t("settings")}</Link>}
          key={TABS_KEYS.SETTINGS}
        >
          <Settings isLoading={isLoading} data={testData} />
        </TabPane>
      </Tabs>
    </PageHeader>
  );
};
