import React from "react";
import { Result, Button } from "antd";
import { Routes } from "../../../constants";

interface Error404Props {
  errorMessage: string;
}

export const Error404 = ({ errorMessage }: Error404Props): JSX.Element => {
  const code = "404";
  return (
    <Result
      status={code}
      title={code}
      subTitle={errorMessage}
      extra={
        <Button
          type='primary'
          htmlType='submit'
          className='rounded-md bg-blue-300 p-1'
          href={Routes.MAIN}
        >
          На главную
        </Button>
      }
    />
  );
};
