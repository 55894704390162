import React, { useCallback, useEffect, useRef } from "react";
import { CardTestItem } from "../../pages";
import Title from "antd/lib/typography/Title";
import { StyledQuestionNameInput } from "./styles";
import { useMutation } from "react-query";
import { editTestRequest } from "../../api/test";
import { invalidateTestRequest } from "../../utils/invalidation";
import { useRecoilValue } from "recoil";
import { currentTestValue } from "../../recoil/selectors";
import { debounce } from "lodash";

interface TitleCardProps {
  isActive: boolean;
  showTitle?: boolean;
  testData: CardTestItem;
}

export const TitleCard = ({
  showTitle,
  testData,
}: TitleCardProps): JSX.Element => {
  const currentTest = useRecoilValue(currentTestValue);

  const updateQuestionMutation = useMutation(
    async (newTestData: CardTestItem) => {
      return editTestRequest(newTestData).then(() => {
        invalidateTestRequest(currentTest.hash);
      });
    }
  );

  const debouncedInfoUpdate = useRef(
    debounce(async ({ value, isName }) => {
      const newTestData: CardTestItem = {
        ...currentTest,
        slug: "slug",
        ...(isName ? { name: value } : { description: value }),
      };
      updateQuestionMutation.mutate(newTestData);
    }, 1000)
  ).current;

  useEffect(() => {
    return () => {
      debouncedInfoUpdate.cancel();
    };
  }, [debouncedInfoUpdate]);

  const onTitleChange = useCallback(
    (e) => {
      debouncedInfoUpdate({ value: e.target.value, isName: true });
    },
    [debouncedInfoUpdate]
  );

  const onDescriptionChange = useCallback(
    (e) => {
      debouncedInfoUpdate({ value: e.target.value, isName: false });
    },
    [debouncedInfoUpdate]
  );

  return (
    <>
      {showTitle && <Title level={4}>Название</Title>}
      <StyledQuestionNameInput
        onChange={onTitleChange}
        defaultValue={testData.name}
        size={"large"}
        className={"text-lg"}
        bordered={false}
        $isBackground={false}
        maxLength={100}
        autoSize
      />
      <br />
      {showTitle && <Title level={5}>Описание</Title>}
      <StyledQuestionNameInput
        maxLength={1000}
        onChange={onDescriptionChange}
        defaultValue={testData.description}
        bordered={false}
        $isBackground={false}
        autoSize
      />
    </>
  );
};
