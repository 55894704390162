import React from "react";

interface TestInfoBlockProps {
  primaryText: string;
  secondaryText: string;
}

export const TestInfoBlock = ({
  primaryText,
  secondaryText,
}: TestInfoBlockProps): JSX.Element => {
  return (
    <div className='bg-gray-100 flex-1 justify-center'>
      <p className='font-bold text-center'>{primaryText}</p>
      <p className='text-center'>{secondaryText}</p>
    </div>
  );
};
