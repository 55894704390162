import React from "react";
import { CardTestItem } from "../../pages";
import { Content } from "./Content";
import { NavigationStatisticsCard } from "../Statistics/NavigationStatisticsCard";
import { NavigationStatisticsRouterCard } from "../Statistics";

interface AnswersProps {
  testData: CardTestItem;
  isLoading: boolean;
}

export const Answers = ({ isLoading, testData }: AnswersProps): JSX.Element => {
  return (
    <Content isLoading={isLoading}>
      <div>
        <NavigationStatisticsCard data={testData} />
        <NavigationStatisticsRouterCard />
      </div>
    </Content>
  );
};
