import React, { useCallback } from "react";
import { useMutation } from "react-query";
import { FontSizeOutlined, PlusOutlined } from "@ant-design/icons";
import { constructorMenuButtonStyles } from "../../utils";
import { Colors } from "../../constants";
import { Button } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Tooltip } from "../Tooltip";
import { createNewQuestionRequest, editTestRequest } from "../../api/test";
import {
  invalidateTestQuestionRequest,
  invalidateTestRequest,
} from "../../utils/invalidation";
import { useRecoilValue } from "recoil";
import {
  currentTestHash,
  currentTestId,
  currentTestValue,
} from "../../recoil/selectors";
import { IQuestion } from "../Question/types";
import { CardTestItem } from "../../pages";

interface ConstructorMenuProps {
  isActive: boolean;
  cardOrder: IQuestion[];
  question?: IQuestion;
}

export const ConstructorMenu = ({
  isActive,
  cardOrder,
  question,
}: ConstructorMenuProps): JSX.Element => {
  const { t } = useTranslation();
  const testHash = useRecoilValue(currentTestHash);
  const testId = useRecoilValue(currentTestId);
  const test = useRecoilValue(currentTestValue);

  const updateQuestionMutation = useMutation(
    async (newTestData: CardTestItem) => {
      return editTestRequest(newTestData)
        .then(() => invalidateTestRequest(testHash))
        .then(() => invalidateTestQuestionRequest(testHash));
    }
  );

  const createQuestionMutation = useMutation(async () => {
    const newQuestionData = {
      name: "Название вопроса",
      description: "Описание вопроса",
      type: 1,
      test: testId,
      hash: testHash,
    };

    return createNewQuestionRequest( newQuestionData ).then((response) => {
      const question_order: number[] = [...cardOrder.map((i) => i.id)];
      const qIdx = cardOrder.findIndex((item) => item.id === question?.id);
      if (response?.data) {
        const newIdx = response?.data.id;
        if (question?.id) {
          if (qIdx === -1) {
            question_order.push(newIdx);
          } else {
            if (qIdx === question_order.length - 1) {
              question_order.push(newIdx);
            } else {
              question_order.splice(qIdx + 1, 0, newIdx);
            }
          }
        } else {
          question_order.unshift(newIdx);
        }
      }
      const newTestData: CardTestItem = {
        ...test,
        question_order: question_order,
      };
      updateQuestionMutation.mutate(newTestData);
    });
  });

  const onCreateQuestion = useCallback(() => {
    createQuestionMutation.mutate();
  }, [createQuestionMutation]);

  const menuButtonClassName = "border-0 rounded-lg my-1";

  return (
    <div className={"mx-2"} style={{ minWidth: 40 }}>
      {isActive && (
        <div
          className='rounded-lg flex flex-col justify-items-center items-center shadow-lg border-2'
          style={{ backgroundColor: "white" }}
        >
          <Tooltip title={t("create_question")}>
            <StyledMenuButton
              icon={<PlusOutlined style={constructorMenuButtonStyles} />}
              className={menuButtonClassName}
              onClick={onCreateQuestion}
            />
          </Tooltip>
          <Tooltip title={t("create_title_and_description")}>
            <StyledMenuButton
              icon={<FontSizeOutlined style={constructorMenuButtonStyles} />}
              className={menuButtonClassName}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

const StyledMenuButton = styled(Button)`
  padding: 0 2px;
  width: 32px !important;
  height: 32px;

  &:hover {
    background-color: ${Colors.blue_1};
  }
`;
