import React, { useCallback } from "react";
import { Card } from "antd";
import { CardTestItem } from "../../pages";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useRecoilValue } from "recoil";
import { currentTestHash } from "../../recoil/selectors";
import { createSessionRequest } from "../../api/test";
import {renderDescription} from "../../utils/renderDescription";

interface StartTestModalProps {
  isLoading: boolean;
  data?: CardTestItem;
  onClick: () => void;
  setSession: (value: string) => void;
}

export const StartTestModal = ({
  isLoading,
  data,
  onClick,
  setSession,
}: StartTestModalProps): JSX.Element => {
  const { t } = useTranslation();
  const testHash = useRecoilValue(currentTestHash);

  const startTestMutation = useMutation(async () => {
    return createSessionRequest(testHash)
      .then((response) => {
        if (response?.data?.session_hash) {
          setSession(response?.data?.session_hash);
        }
      })
      .then(() => {
        onClick();
      })
      .catch(() => console.log("Failed to start"));
  });

  const onStartTest = useCallback(() => {
    startTestMutation.mutate();
  }, [startTestMutation]);

  return (
    <Card
      loading={isLoading}
      style={{ width: "50%" }}
      title={
        <span>
          <span className='font-semibold'>Название:</span> {data?.name}
        </span>
      }
      actions={[
        <PrimaryButton
          key='start'
          onClick={onStartTest}
          title={t("start_test")}
          loading={startTestMutation.isLoading}
        />,
      ]}
      className='w-full rounded-lg shadow-md hover:shadow-xl'
    >
      <div>
        <p>
          <span className='font-semibold'>Дата создания:</span>{" "}
          {data?.created_at &&
            new Date(Date.parse(data?.created_at)).toLocaleString("ru-RU")}
        </p>
      </div>
      {data?.description ? (
        <div>
          <p>
            <span className='font-semibold'>Описание</span>
            {renderDescription(data.description)}
          </p>
        </div>
      ) : null}
    </Card>
  );
};
