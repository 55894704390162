import React, { useCallback, useState, useEffect } from "react";
import { Input, Button, Upload, Col, Row, message } from "antd";
import { UploadProps } from "antd/es";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { StyledContent } from "./styles";
import { TestsList } from "../../components/TestsList";
import { CardTestItem } from "./types";
import { useQuery } from "react-query";
import { PageHeader } from "../../components/PageHeader";
import axios from "axios";
import { getBaseUrl } from "../../utils/getEnv";

export const Main = (): JSX.Element => {
  useEffect(() => {
    const fetchDataFromServer = async () => {
      try {
        const response = await axios.get(
          "http://http://127.0.0.1:8000/v1.0/auth/get_session_data/"
        );
        const dataFromServer = response.data;

        // Вывод данных в консоль
        console.log(131414);
        console.log("Полученные данные из сервера:", dataFromServer);

        // Действия с полученными данными на вашей странице
      } catch (error) {
        // Обработка ошибок
        console.error("Ошибка:", error);
      }
    };

    fetchDataFromServer();
  }, []);

  const user_token = localStorage.getItem("access-token");
  const { data, isLoading } = useQuery<CardTestItem[]>("tests/");
  const [testData, setTestData] = useState<string | ArrayBuffer | null>("");

  const props: UploadProps = {
    onRemove: () => {
      setTestData(null);
    },
    beforeUpload: (file) => {
      const testFile = file;
      const reader = new FileReader();
      reader.readAsText(testFile);
      reader.onload = (e) => {
        setTestData(reader.result ?? "");
      };
      reader.onerror = () => {
        console.log(reader.error);
      };

      return false;
    },
  };

  const UploadTest = useCallback(() => {
    if (typeof testData === "string") {
      const jsonObj = JSON.parse(testData);
      axios
        .post(`${getBaseUrl()}/v1.0/tests/import/`, jsonObj, {
          headers: {
            Authorization: "Token " + user_token,
          },
        })
        .then((data) => {
          if (data.status === 201) {
            message.success("Тест загружен");
            setTestData(null);
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [user_token, testData]);

  const routes = [
    {
      path: "/",
      breadcrumbName: "Мои тесты",
    },
  ];

  return (
    <StyledContent>
      <PageHeader routes={routes} title='Ваши тесты' isLoading={isLoading}>
        <Input
          size='middle'
          placeholder='Поиск теста'
          className='w-1/2 rounded-lg '
          style={{ marginLeft: 24, marginTop: 12, marginBottom: 12 }}
          prefix={<SearchOutlined />}
        />
        <Row style={{ marginLeft: 24 }}>
          <Col span={12}>
            <Upload
              {...props}
              // style={{ marginBottom: 60, }}
              maxCount={1}
              accept={".json"}
            >
              <Button icon={<UploadOutlined />}>Загрузить тест</Button>
            </Upload>
          </Col>
          <Col span={12}>
            <Button
              type={"primary"}
              disabled={!testData}
              htmlType='submit'
              onClick={UploadTest}
              style={{ marginLeft: 8, marginBottom: 40 }}
            >
              Загрузить
            </Button>
          </Col>
        </Row>
      </PageHeader>

      {/*<MainPageControls onClick={onClick} />*/}

      <TestsList data={data ?? []} isLoading={isLoading} />
    </StyledContent>
  );
};
