import axios from "axios";
import { getBaseUrl } from "../utils/getEnv";
import { Routes } from "../constants";

const apiClient = axios.create({
  baseURL: `${getBaseUrl()}` + Routes.API_VERSION,
});

apiClient.interceptors.request.use((config) => {
  if (config.url) {
    const token = localStorage.getItem("access-token");
    config.headers.Authorization = `Token ${token}`;
  }

  return config;
});

export { apiClient };
