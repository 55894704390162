import React, { CSSProperties } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Colors } from "../../constants";

interface QuestionAnswerStatusProps {
  correct: boolean;
  showWrong?: boolean;
  wrapperStyles?: CSSProperties;
}

export const QuestionAnswerStatus = ({
  correct,
  showWrong = false,
  wrapperStyles,
}: QuestionAnswerStatusProps): JSX.Element => {
  return (
    <>
      {!(!correct && !showWrong) ? (
        <div style={wrapperStyles}>
          {correct ? (
            <CheckOutlined style={{ color: Colors.green_7, fontSize: 24 }} />
          ) : showWrong ? (
            <CloseOutlined style={{ color: Colors.red_5, fontSize: 24 }} />
          ) : null}
        </div>
      ) : null}
    </>
  );
};
