import React, { useCallback, useState, useEffect } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Colors, Routes as R } from "../../constants";
import { Layout, Menu as AntdMenu } from "antd";
import {
  LaptopOutlined,
  LogoutOutlined,
  NotificationOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import { antIconStyles } from "../../utils";
import { useMutation } from "react-query";
import { CardTestItem } from "../../pages";
import { CheckUserAuth, createNewTestRequest } from "../../api/test";
import { isSuccessfulAndWithData } from "../../api/api";

const { SubMenu } = AntdMenu;
const { Sider } = Layout;

const MENU_WIDTH = 200;

export const StyledNavLink = styled(NavLink)`
  color: #677693;
  font-size: 0.9rem;
  padding: 4px 0;
  text-align: center;
  margin-left: 0.5rem;

  &.active {
    font-weight: 500;
  }
`;

export const Menu = (): JSX.Element => {
  const location = useLocation();
  const history = useHistory();
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    if (
      location.pathname.startsWith(
        R.START_TEST.substring(0, R.START_TEST.length - 5)
      )
    ) {
      CheckUserAuth()
        .then((response) => {
          return response?.data;
        })
        .then((data: any) => {
          setAuthorized(!!data?.email);
        });
    } else {
      setAuthorized(true);
    }
  }, [authorized, location.pathname]);

  const isOpen = !(
    location.pathname.startsWith(R.LOGIN) ||
    location.pathname.startsWith(R.REGISTRATION)
  );

  const [collapsed, setCollapsed] = useState(false);
  const createTestMutation = useMutation(
    async (newTextData: Partial<CardTestItem>) => {
      return createNewTestRequest(newTextData).then((response) => {
        if (isSuccessfulAndWithData(response)) {
          history.push(`/constructor/${response?.data?.hash}/`);
        }
      });
    }
  );

  const onMenuClick = useCallback(() => {
    const newTestData = {
      name: "Название теста",
      description: "Описание теста",
    };
    createTestMutation.mutate(newTestData);
  }, [createTestMutation]);

  return (
    <>
      {isOpen && authorized && (
        <Sider
          // collapsedWidth="0"
          width={MENU_WIDTH}
          className='site-layout-background'
          theme='light'
          collapsed={collapsed}
          onCollapse={() => {
            setCollapsed(!collapsed);
          }}
        >
          <AntdMenu
            mode='inline'
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            style={{ height: "100%" }}
          >
            <AntdMenu.Item
              key='0'
              icon={<PlusOutlined style={antIconStyles} />}
              onClick={onMenuClick}
            >
              Создать тест
            </AntdMenu.Item>
            <SubMenu
              key='sub1'
              icon={<UserOutlined style={antIconStyles} />}
              title='Tесты'
            >
              <AntdMenu.Item key='1' onClick={() => history.push("/")}>
                Созданные
              </AntdMenu.Item>
              <AntdMenu.Item disabled key='2'>
                Пройденные
              </AntdMenu.Item>
              <AntdMenu.Item disabled key='3'>
                Анализ
              </AntdMenu.Item>
            </SubMenu>
            <SubMenu
              disabled
              key='sub2'
              icon={<LaptopOutlined />}
              title='Анализ'
            >
              <AntdMenu.Item disabled key='5'>
                option5
              </AntdMenu.Item>
              <AntdMenu.Item disabled key='6'>
                option6
              </AntdMenu.Item>
              <AntdMenu.Item disabled key='7'>
                option7
              </AntdMenu.Item>
              <AntdMenu.Item disabled key='8'>
                option8
              </AntdMenu.Item>
            </SubMenu>
            <SubMenu
              key='sub3'
              icon={<NotificationOutlined style={antIconStyles} />}
              title='Генерация'
              disabled
            >
              <AntdMenu.Item disabled key='9'>
                option9
              </AntdMenu.Item>
              <AntdMenu.Item disabled key='10'>
                option10
              </AntdMenu.Item>
              <AntdMenu.Item disabled key='11'>
                option11
              </AntdMenu.Item>
              <AntdMenu.Item disabled key='12'>
                option12
              </AntdMenu.Item>
            </SubMenu>
            <AntdMenu.Item
              key='sub4'
              icon={<LogoutOutlined style={antIconStyles} />}
              onClick={() => {
                history.push(R.LOGOUT);
              }}
              style={{
                color: Colors.red_5,
              }}
            >
              Выход
            </AntdMenu.Item>
          </AntdMenu>
        </Sider>
      )}
    </>
  );
};
