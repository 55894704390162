import React, { useCallback, useEffect, useState } from "react";
import { StatisticsCard } from "./StatisticsCard";
import { HashLink as Link } from "react-router-hash-link";
import { Button, Tabs } from "antd";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TABS_KEYS } from "../Constructor/ConstructorBase";
import { QuestionPagination } from "./QuestionPagination";
import { getBaseUrl } from "../../utils/getEnv";
import {
  getDownloadTestDataAPI,
  getStatisticsTestAnswersAPI,
  Routes,
} from "../../constants";
import { CardTestItem } from "../../pages";
import { useQuery } from "react-query";
import { TestAnswersStatistics } from "./types";

const { TabPane } = Tabs;

export enum ANSWERS_TABS_KEYS {
  TEST = "constructor",
  QUESTION = "#question",
  USER = "#user",
}

interface AnswersProps {
  data: CardTestItem;
}

export const NavigationStatisticsCard = ({
  data,
}: AnswersProps): JSX.Element => {
  const location = useLocation();

  const { data: result, isLoading } = useQuery<TestAnswersStatistics>(
    getStatisticsTestAnswersAPI(data.hash),
    {
      enabled: data.hash.length > 0,
    }
  );

  const [activeKey, setActiveKey] = useState<ANSWERS_TABS_KEYS>(
    ANSWERS_TABS_KEYS.TEST
  );

  useEffect(() => {
    if (location.hash === TABS_KEYS.ANSWERS) {
      setActiveKey(ANSWERS_TABS_KEYS.TEST);
    } else if (location.hash.includes(ANSWERS_TABS_KEYS.QUESTION)) {
      setActiveKey(ANSWERS_TABS_KEYS.QUESTION);
    } else {
      setActiveKey(ANSWERS_TABS_KEYS.USER);
    }
  }, [location]);
  const { t } = useTranslation();

  const handleClick = useCallback((element, e) => {
    window.open(
      `${getBaseUrl()}` +
        Routes.API_VERSION +
        getDownloadTestDataAPI(element.hash)
    );
  }, []);

  return (
    <StatisticsCard
      cardTitle={`Ответов: ${!isLoading ? result?.sessions : "0"}`}
      bodyStyle={{ padding: 24, paddingBottom: 0, textAlign: "center" }}
    >
      <Button onClick={(e) => handleClick(data, e)} type='primary'>
        Скачать все ответы в xlsx формате
      </Button>
      <Tabs
        activeKey={activeKey}
        centered
        tabBarStyle={{
          margin: 0,
          height: 48,
          border: 0,
          borderColor: "unset",
          flexGrow: 1,
        }}
        size={"large"}
      >
        <TabPane
          tab={
            <Link to={TABS_KEYS.ANSWERS} title=''>
              {t("test")}
            </Link>
          }
          key='constructor'
        />
        <TabPane
          tab={<Link to={ANSWERS_TABS_KEYS.QUESTION}> {t("question")}</Link>}
          key={ANSWERS_TABS_KEYS.QUESTION}
        >
          <QuestionPagination />
        </TabPane>
        <TabPane
          tab={<Link to={ANSWERS_TABS_KEYS.USER}> {t("user")}</Link>}
          key={ANSWERS_TABS_KEYS.USER}
        />
      </Tabs>
    </StatisticsCard>
  );
};
