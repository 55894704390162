export const getConstrictorTestQuestionsAPI = (hash: string): string => {
  return `/tests/${hash}/questions/`;
};

export const getConstrictorTestAPI = (hash: string): string => {
  return `/tests/${hash}/`;
};

export const getConstrictorTestRunAPI = (hash: string): string => {
  return `/tests/${hash}/sessions`;
};

export const getCoreQuestionsAPI = (hash: string, id: number): string => {
  return `/tests/${hash}/questions/${id}/`;
};

export const getCoreQuestionAnswersAPI = (hash: string, id: number): string => {
  return `/tests/${hash}/questions/answers/${id}/`;
};

export const getCoreCreateQuestionAnswersAPI = (hash: string): string => {
  return `/tests/${hash}/questions/answers/`;
};

export const getCreateTestAPI = (): string => {
  return "/tests/";
};

export const getCreateAnswerAPI = (): string => {
  return "/core/answer-new/";
};

export const getCreateQuestionAPI = (): string => {
  return `/tests/questions/`;
};

export const getTestQuestionAnswersAPI = (hash: string, id: number): string => {
  return `/tests/${hash}/answers/${id}/`;
};

export const getCreateSessionAPI = (): string => {
  return `/tests/sessions/start/`;
};

export const getCoreUserQuestionsAPI = (hash: string): string => {
  return `/tests/${hash}/questions/`;
};

export const getCoreUserAnswerAPI = (
  hash: string,
  questionId: number
): string => {
  return `/tests/user/answer/${hash}/${questionId}/`;
};

export const getEndSessionAPI = (): string => {
  return `/tests/sessions/`;
};

export const getDownloadTestDataAPI = (hash: string): string => {
  return `tests/${hash}/download/`;
};

export const getDownloadTestDOCX = (hash: string): string => {
  return `tests/test-question-export/${hash}/docx/`;
};

export const getDownloadTestJSON = (hash: string): string => {
  return `tests/test-question-export/${hash}/json/`;
};

export const checkUserAuthAPI = (): string => {
  return `/tests/profile/`;
};

export const getTestQuestionPaginationConfigAPI = (hash: string): string => {
  return `/tests/test-question-pagination/${hash}/`;
};

export const getStatisticsQuestionAnswersAPI = (
  hash: string,
  id: number
): string => {
  return `/analytics/answers/${hash}/${id}/`;
};

export const getStatisticsTestAnswersAPI = (hash: string): string => {
  return `/analytics/test/${hash}/`;
};

export const getAnalyticsSummaryAPI = (hash: string): string => {
  return `/analytics/tests/${hash}/summary`;
};

export const getQuestionsListAPI = (hash: string): string => {
  return `/analytics/tests/${hash}/questions`;
};

export const getOneQuestionDataAPI = (hash: string, id: number): string => {
  return `/analytics/test/${hash}/questions/${id}/responses`;
};

export const getOneUserAnswersDataAPI = (hash: string, id: number): string => {
  return `/analytics/test/${hash}/users/${id}/responses`;
};
