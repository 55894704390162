import React, { useCallback, useState } from "react";
import { Tabs } from "antd";
import { TabTitle } from "./TabTitle";
import { LoginForm } from "../LoginForm";
import { RegistrationForm } from "../LoginForm/RegistrationForm";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;

export enum TabKeys {
  LOGIN = "login",
  REGISTRATION = "registration",
}

export const LoginAndRegistrationTabs = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState<TabKeys>(TabKeys.LOGIN);

  const { t } = useTranslation();

  const onTabChanged = useCallback((key) => {
    setActiveTab(key);
  }, []);

  return (
    <Tabs
      activeKey={activeTab}
      onChange={onTabChanged}
      style={{
        marginTop: "60%",
        minWidth: 300,
      }}
    >
      <TabPane
        tab={
          <TabTitle
            text={t("login_page.login")}
            isActiveTab={activeTab === TabKeys.LOGIN}
          />
        }
        key={TabKeys.LOGIN}
      >
        <LoginForm />
      </TabPane>
      <TabPane
        tab={
          <TabTitle
            text={t("login_page.registration")}
            isActiveTab={activeTab === TabKeys.REGISTRATION}
          />
        }
        key={TabKeys.REGISTRATION}
      >
        <RegistrationForm onSuccess={() => setActiveTab(TabKeys.LOGIN)} />
      </TabPane>
    </Tabs>
  );
};
