import React, { useEffect, useState } from "react";
import { IQuestionTypes } from "../Question/types";
import { Select } from "antd";

interface EditQuestionTypeSelectProps {
  type: IQuestionTypes;
  updateQType: (type: IQuestionTypes) => void;
}

const QuestionTypeColors: Record<string, string> = {
  [IQuestionTypes.TEXT]: "Текст (Строка)",
  [IQuestionTypes.PARAGRAPH]: "Текст (Абзац)",
  [IQuestionTypes.MATCHING]: "Соответствие",
  [IQuestionTypes.MULTIPLE]: "Несколько из списка",
  [IQuestionTypes.SINGLE]: "Один из списка",
  [IQuestionTypes.SEQUENCE]: "Последовательность",
  [IQuestionTypes.SYSTEM]: "Системное (скрытое)",
};

const getColorByType = (type: IQuestionTypes): string => {
  switch (type) {
    case IQuestionTypes.MATCHING:
      return "bg-red-100";
    case IQuestionTypes.MULTIPLE:
      return "bg-yellow-100";
    case IQuestionTypes.SEQUENCE:
      return "bg-pink-100";
    case IQuestionTypes.TEXT:
      return "bg-green-100";
    case IQuestionTypes.SINGLE:
      return "bg-blue-100";
    case IQuestionTypes.PARAGRAPH: case IQuestionTypes.SYSTEM:
      return "bg-orange-100";
    default:
      return "bg-gray-100";
  }
};

export const EditQuestionTypeSelect = ({
  type,
  updateQType,
}: EditQuestionTypeSelectProps): JSX.Element => {
  const [questionType, setQestionType] = useState(type);

  useEffect(() => {
    setQestionType(type);
  }, [type]);

  return (
    <Select
      value={questionType}
      className={`ml-2 font-semibold rounded-md w-full ${getColorByType(type)}`}
      // bordered={false}
      size='large'
      onSelect={(_, value) => {
        if (value) {
          updateQType(value.key as IQuestionTypes);
          setQestionType(value.key as IQuestionTypes);
        }
      }}
    >
      {Object.keys(QuestionTypeColors).map((item) => (
        <Select.Option key={item} value={item} className={`font-semibold`}>
          {QuestionTypeColors[item]}
        </Select.Option>
      ))}
    </Select>
  );
};
