import React, { useCallback, useEffect, useState } from "react";
import { RadioBlock, RawAnswerText } from "../styles";
import { EditAnswerInput } from "../EditAnswerInput";
import { DeleteAnswer } from "../DeleteAnswer";
import { SingleAnswerProps } from "./types";
import { CorrectAnswerWrapper } from "./CorrectAnswerWrapper";

export const RadioAnswer = ({
  answer,
  isActive,
  inputRef,
  isChangeCorrectAnswers,
  updateAnswerValue,
}: SingleAnswerProps): JSX.Element => {
  const [isCorrect, setIsCorrect] = useState<boolean>(answer.is_correct);

  useEffect(() => {
    setIsCorrect(answer.is_correct);
  }, [answer.is_correct]);

  const onClick = useCallback(() => {
    updateAnswerValue({
      ...answer,
      is_correct: !isCorrect,
    });

    setIsCorrect(!isCorrect);
  }, [isCorrect, answer, updateAnswerValue]);

  return (
    <div
      key={`answer_radio_${answer.id}_${answer.value}`}
      className='flex align-center my-2'
    >
      {isChangeCorrectAnswers ? (
        <CorrectAnswerWrapper isCorrect={isCorrect} onClick={onClick}>
          <RadioBlock />
          <RawAnswerText>{answer.value}</RawAnswerText>
        </CorrectAnswerWrapper>
      ) : (
        <div className='flex px-2 w-full'>
          <RadioBlock />
          <EditAnswerInput
            key={answer.value + "_" + answer.id}
            answerResponse={answer}
            inputRef={inputRef}
          />
          {isActive ? <DeleteAnswer answerId={answer.id} /> : null}
        </div>
      )}
    </div>
  );
};
