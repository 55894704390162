import React from "react";
import { CardTestItem } from "../../pages";
import { Badge, Card } from "antd";
import { TestSelectStatuses } from "../TestStatusSelect";
import { EditButton, PlayButton } from "./ActionButtons";
import { useTranslation } from "react-i18next";

interface CardProps {
  item: CardTestItem;
}

export const MainPageCard = ({ item }: CardProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Badge.Ribbon
        text={
          <span className='text-gray-700'>
            {item.isActive
              ? TestSelectStatuses.Active
              : TestSelectStatuses.Inactive}
          </span>
        }
        className={`${
          item.isActive ? "bg-green-200" : "bg-red-200"
        } font-semibold `}
      >
        <Card
          title={
            <span>
              <span className='font-semibold'>{t("test_name")}</span>{" "}
              {item.name}
            </span>
          }
          className='rounded-lg cursor-pointer shadow-md hover:shadow-xl'
          actions={[
            <PlayButton key='run' item={item} />,
            <EditButton key='edit' item={item} />,
            // <EllipsisOutlined key='ellipsis' />,
          ]}
          style={{}}
        >
          <div>
            <p>
              <span className='font-semibold'>{t("date_creation")}</span>{" "}
              {item?.created_at &&
                new Date(Date.parse(item?.created_at)).toLocaleString("ru-RU")}
            </p>
          </div>
          {item.description ? (
            <div>
              <p>
                <span className='font-semibold'>{t("test_description")}</span>
                {item.description}
              </p>
            </div>
          ) : null}
        </Card>
      </Badge.Ribbon>
    </>
  );
};
