import React, { useCallback, useState } from "react";
import { Pagination } from "antd";
import { useQuery } from "react-query";
import { getTestQuestionPaginationConfigAPI } from "../../constants";
import { useRecoilValue } from "recoil";
import { currentTestHash } from "../../recoil/selectors";
import { TestQuestionPaginationConfig } from "./types";
import { useHistory, useLocation } from "react-router-dom";

export const QuestionPagination = (): JSX.Element => {
  const testHash = useRecoilValue(currentTestHash);
  const location = useLocation();
  const history = useHistory();
  const locationQuestionId = location.hash.split("=")[1];
  const currentQuestion = locationQuestionId != null ? +locationQuestionId : 1;
  const [currentQuestionIdx, setCurrentQuestionIdx] = useState(currentQuestion);

  const { data } = useQuery<TestQuestionPaginationConfig>(
    getTestQuestionPaginationConfigAPI(testHash),
    {
      enabled: testHash.length > 0,
    }
  );

  const onPaginationChanged = useCallback(
    (page: number) => {
      setCurrentQuestionIdx(page);
      history.push(`#question=${data?.question_order_ids[page - 1]}`);
    },
    [data, history]
  );

  return (
    <div className='my-2'>
      {data && (
        <Pagination
          simple
          total={data.question_count}
          pageSize={1}
          hideOnSinglePage
          current={currentQuestionIdx}
          onChange={onPaginationChanged}
        />
      )}
    </div>
  );
};
