import { Rule } from "rc-field-form/lib/interface";
import i18n from "../i18n";

export const makeRequiredFormFieldRule = (
  message = i18n.t("required_field")
): Rule => ({
  required: true,
  message: message,
});

export const makeMinFormFieldRule = (
  message = i18n.t("min_character"),
  min = 8
): Rule => ({
  min,
  message: message + min,
});
