import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { AnswerResponse } from "../Answer/types";
import { useMutation } from "react-query";
import { StyledInput } from "./styles";
import { QuestionAnswerStatus } from "../QuestionAnswerStatus";
import { useRecoilValue } from "recoil";
import { currentTestHash } from "../../recoil/selectors";
import { editTestQuestionAnswerRequest } from "../../api/test";
import { invalidateTestQuestionRequest } from "../../utils/invalidation";
import { debounce } from "lodash";

interface EditAnswerInputProps {
  answerResponse: AnswerResponse;
  inputRef: MutableRefObject<any> | null;
}

export const EditAnswerInput = ({
  answerResponse,
  inputRef,
}: EditAnswerInputProps): JSX.Element => {
  const [answer, setAnswer] = useState(answerResponse.value);
  const testHash = useRecoilValue(currentTestHash);

  useEffect(() => {
    setAnswer(answerResponse.value);
  }, [answerResponse]);

  const updateAnswerMutation = useMutation(async (newData: AnswerResponse) => {
    return editTestQuestionAnswerRequest(newData, testHash)
      .then(() => {
        invalidateTestQuestionRequest(testHash);
      })
      .catch((err) => console.log(err));
  });

  const debouncedNameUpdate = useRef(
    debounce(async (value) => {
      const newAnswer: AnswerResponse = {
        ...answerResponse,
        value,
      };
      updateAnswerMutation.mutate(newAnswer);
    }, 500)
  ).current;

  const onNameChange = useCallback(
    (e) => {
      debouncedNameUpdate(e.target.value);
    },
    [debouncedNameUpdate]
  );

  useEffect(() => {
    return () => {
      debouncedNameUpdate.cancel();
    };
  }, [debouncedNameUpdate]);

  useEffect(() => {
    inputRef?.current?.focus({
      cursor: "end",
    });
  }, [inputRef]);
  return (
    <>
      <StyledInput
        defaultValue={answer}
        bordered={false}
        onBlur={onNameChange}
        ref={inputRef}
        autoSize={true}
      />
      <QuestionAnswerStatus
        correct={answerResponse.is_correct}
        wrapperStyles={{ marginLeft: 16 }}
      />
    </>
  );
};
