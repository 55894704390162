import { QueryClient, QueryFunctionContext } from "react-query";
import { AxiosResponse } from "axios";
import {
  ExtendedAxiosResponse,
  ErrorResponseResult,
  ResponseResult,
  SuccessResponseResult,
} from "./types";
import { makeRequest } from "./api";
import { apiClient } from "./apiClient";

async function queryFn({
  queryKey,
}: QueryFunctionContext): Promise<
  | SuccessResponseResult<AxiosResponse<ResponseResult<unknown>>>
  | ErrorResponseResult<AxiosResponse<ResponseResult<unknown>>>
> {
  const url = queryKey[0];
  if (typeof url !== "string") {
    throw new Error("URL must be set");
  }
  return makeRequest<AxiosResponse<ResponseResult<unknown>>>(() =>
    apiClient.get(url)
  );
}

function select(result: unknown): unknown {
  // данная функция выбирает данные и помещает их в data
  return (result as ExtendedAxiosResponse)?.data; // ExtendedAxiosResponse так как у нас есть
} // дополнительное поле success

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn,
      // TODO: добавить поле success в ответ или
      //  поменять поле isSuccess=false если в объекте src/api/api.ts/makeRequest success=false
      //  ссылки
      //  1 - https://react-query.tanstack.com/guides/mutations
      //  2 - https://github.com/harryheman/React-Total/blob/main/md/react-query.md
      select,
      refetchOnWindowFocus: false,
    },
  },
});
