import React from "react";
import { useParams } from "react-router-dom";
import { CardTestItem, StyledContent } from "../Main";
import { useQuery } from "react-query";
import { getConstrictorTestAPI } from "../../constants";
import { useRecoilState } from "recoil";
import { currentTest } from "../../recoil";
import { ConstructorBase } from "../../components/Constructor/ConstructorBase";
import { Error404 } from "../../components/Errors/Error404";

export const TestConstructorPage = (): JSX.Element => {
  const { id: hash } = useParams<{ id: string }>();
  const [, setCurrentTestValue] = useRecoilState(currentTest);

  const { data, isLoading } = useQuery<CardTestItem>(
    getConstrictorTestAPI(hash),
    {
      enabled: hash.length > 0,
      onError: (err) => {
        console.log(err);
      },
      onSuccess: (backendData: CardTestItem) => {
        setCurrentTestValue(backendData);
      },
    }
  );

  return (
    <StyledContent>
      <div className='h-full'>
        {/* TODO: Поменять !data на !idSuccess после редактирования src/api/queryClient */}
        {!data && !isLoading && <Error404 errorMessage="Тест не найден" />}
        {data && <ConstructorBase testData={data} isLoading={isLoading} />}
      </div>
    </StyledContent>
  );
};
