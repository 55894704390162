import { selector } from "recoil";
import { currentTest } from "./atoms";

export const currentTestHash = selector({
  key: "currentTestHash",
  get: ({ get }) => {
    const test = get(currentTest);

    return test?.hash ?? "";
  },
});

export const currentTestValue = selector({
  key: "currentTestValue",
  get: ({ get }) => {
    return get(currentTest);
  },
});

export const currentTestId = selector({
  key: "currentTestId",
  get: ({ get }) => {
    return get(currentTest).id;
  },
});
