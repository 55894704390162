import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { CardTestItem } from "../../pages";
import {
  getQuestionTypeByNumberType,
  IQuestion,
  IQuestionTypes,
} from "../Question/types";

import { Checkbox, Form, Input, Radio, Space } from "antd";
import { useMutation } from "react-query";
import { UserAnswer } from "./types";
import { useRecoilValue } from "recoil";
import { currentTestHash } from "../../recoil/selectors";
import { createUserAnswerRequest } from "../../api/test";
import { debounce } from "lodash";
import { makeRequiredFormFieldRule } from "../../constants/rules";
import { renderQuestionName } from "../../utils/renderQuestionName";

const { TextArea } = Input;

interface EditQuestionCardProps {
  testData: CardTestItem;
  question?: IQuestion;
  isActive: boolean;
  sessionHash: string;
  form: any;
}

export const EditQuestionCard = ({
  question,
  sessionHash,
  form,
}: EditQuestionCardProps): JSX.Element => {
  console.log("SessionHash:", sessionHash, form);
  const testHash = useRecoilValue(currentTestHash);

  const saveAnswerMutation = useMutation(async (userAnswerData: UserAnswer) => {
    return createUserAnswerRequest(
      userAnswerData,
      testHash,
      question?.id ?? 0
    ).catch((e) => console.log(e));
  });

  const debouncedSearch = useRef(
    debounce(async (value) => {
      if (!question) {
        return;
      }
      const answer_value: Record<string, any> = {
        [question.type]: value,
      };
      const newData: UserAnswer = {
        session_hash: sessionHash,
        type: question.type,
        answer_value,
      };
      form.setFieldsValue({ [question?.name]: { value: answer_value } });
      saveAnswerMutation.mutate(newData);
    }, 500)
  ).current;

  const onTextValueChange = useCallback(
    (e) => {
      debouncedSearch(e.target.value);
    },
    [debouncedSearch]
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const onSingleAnswerChanged = useCallback(
    (event) => {
      console.log(event.target.value);
      if (!question) {
        return;
      }
      const answer_value: Record<string, any> = {
        [question.type]: event.target.value,
      };
      const newData: UserAnswer = {
        session_hash: sessionHash,
        type: question.type,
        answer_value: answer_value,
      };
      form.setFieldsValue({ [question?.name]: { value: answer_value } });

      saveAnswerMutation.mutate(newData);
    },
    [saveAnswerMutation, question, sessionHash, form]
  );

  const onMultipleAnswerChanged = useCallback(
    (values) => {
      console.log(values);
      if (!question) {
        return;
      }
      const answer_value: Record<string, any> = {
        [question.type]: values,
      };
      const newData: UserAnswer = {
        session_hash: sessionHash,
        type: question.type,
        answer_value: answer_value,
      };
      form.setFieldsValue({ [question?.name]: { value: answer_value } });
      saveAnswerMutation.mutate(newData);
    },
    [saveAnswerMutation, question, sessionHash, form]
  );

  const cardName = useMemo(() => renderQuestionName(question), [question]);

  if (!question) {
    return <></>;
  }

  const drawConstructorAnswers = () => {
    switch (getQuestionTypeByNumberType(question.type)) {
      case IQuestionTypes.TEXT:
        return (
          <>
            <p>Краткий ответ</p>
            <p>Ваш ответ: </p>
            <Input size={"small"} onChange={onTextValueChange} />
          </>
        );
      case IQuestionTypes.SINGLE:
        return (
          <>
            <Radio.Group onChange={onSingleAnswerChanged}>
              <Space direction='vertical'>
                {question?.answers?.map((item) => {
                  return (
                    <div key={`answer_single_${item.id}_${item.value}`}>
                      <Radio key={item.value + "_" + item.id} value={item.id}>
                        {item.value}
                      </Radio>
                    </div>
                  );
                })}
              </Space>
            </Radio.Group>
          </>
        );

      case IQuestionTypes.MULTIPLE:
        return (
          <>
            <Checkbox.Group onChange={onMultipleAnswerChanged}>
              <Space direction='vertical'>
                {question?.answers?.map((item) => {
                  return (
                    <div key={`answer_radio_${item.id}_${item.value}`}>
                      <Checkbox
                        key={item.value + "_" + item.id}
                        value={item.id}
                      >
                        {item.value}
                      </Checkbox>
                    </div>
                  );
                })}
              </Space>
            </Checkbox.Group>
          </>
        );
      case IQuestionTypes.PARAGRAPH:
        return (
          <TextArea
            placeholder='Введите ответ'
            autoSize={{ minRows: 2, maxRows: 6 }}
            onChange={onTextValueChange}
          />
        );
      default:
        return <p>Not implemented</p>;
    }
  };

  return (
    <Form.Item
      id={question?.name}
      name={question?.name}
      rules={
        question?.is_required
          ? [makeRequiredFormFieldRule("Это обязательный вопрос!")]
          : []
      }
    >
      {cardName}
      {drawConstructorAnswers()}
    </Form.Item>
  );
};
