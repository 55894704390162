import React, { useCallback, useEffect, useRef, useState } from "react";
import { CardTestItem } from "../../pages";
import { IQuestion } from "../Question/types";
import { useMutation, useQuery } from "react-query";
import { CardType, QuestionCard } from "../QuestionCard";
import { moveInArray } from "../../utils/moveInArray";
import { useDrop } from "react-dnd";
import { getConstrictorTestQuestionsAPI } from "../../constants";
import { editTestRequest } from "../../api/test";
import { invalidateTestRequest } from "../../utils/invalidation";
import { useRecoilValue } from "recoil";
import { currentTestHash } from "../../recoil/selectors";
import { debounce } from "lodash";
import { Content } from "./Content";

interface ConstructorProps {
  testData: CardTestItem;
  isLoading: boolean;
}

export const Constructor = ({
  testData,
  isLoading,
}: ConstructorProps): JSX.Element => {
  const [currentSelectedCard, setCurrentSelectedCard] = useState<number>(-1);
  const [cardOrder, setCardOrder] = useState<IQuestion[]>([]);
  const testHash = useRecoilValue(currentTestHash);
  const { data, isLoading: testQuestionLoading } = useQuery<IQuestion[]>(
    getConstrictorTestQuestionsAPI(testHash),
    {
      enabled: testHash.length > 0,
    }
  );
  useEffect(() => {
    if (data && data.length - 1 < currentSelectedCard) {
      setCurrentSelectedCard(data.length - 1);
    }
  }, [data, currentSelectedCard]);

  useEffect(() => {
    const newData = data ?? [];
    const questionOrder = testData?.question_order;
    if (questionOrder && questionOrder.length > 0) {
      newData.sort((a, b) => {
        return questionOrder.indexOf(a.id) - questionOrder.indexOf(b.id);
      });
    }
    setCardOrder(newData);
  }, [testData, data]);

  const findCard = useCallback(
    (id: number) => {
      const card = cardOrder.filter((c) => c.id === id)[0];
      return {
        card,
        index: cardOrder.indexOf(card),
      };
    },
    [cardOrder]
  );

  const updateQuestionMutation = useMutation(
    async (newTestData: CardTestItem) => {
      return editTestRequest(newTestData).then(() => {
        invalidateTestRequest(testHash);
      });
    }
  );

  const debouncedUpdate = useRef(
    debounce(async (question_order) => {
      const newTestData: CardTestItem = {
        ...testData,
        question_order: question_order,
      };
      updateQuestionMutation.mutate(newTestData);
    }, 1000)
  ).current;

  useEffect(() => {
    return () => {
      debouncedUpdate.cancel();
    };
  }, [debouncedUpdate]);

  const moveCard = useCallback(
    (cardId, targetIdx) => {
      const { index } = findCard(cardId);
      if (cardOrder.length > 0) {
        const newData = moveInArray<IQuestion>(cardOrder, index, targetIdx);
        setCardOrder(newData);
        const newOrder = newData.map((i) => i.id);
        debouncedUpdate(newOrder);
      }
    },
    [cardOrder, findCard, debouncedUpdate]
  );

  const [, drop] = useDrop(() => ({ accept: CardType.QUESTION }));
  return (
    <Content isLoading={isLoading}>
      <div ref={(elem) => drop(elem)}>
        <QuestionCard
          type={CardType.TITLE}
          testData={testData}
          onClick={() => {
            setCurrentSelectedCard(-1);
          }}
          isActive={currentSelectedCard === -1}
          testQuestionLoading={isLoading}
          moveCard={moveCard}
          findCard={findCard}
          cardOrder={cardOrder}
        />

        <div>
          {cardOrder.map((question, idx) => (
            <QuestionCard
              type={CardType.QUESTION}
              key={question.id}
              isActive={currentSelectedCard === idx}
              question={question}
              onClick={() => setCurrentSelectedCard(idx)}
              testData={testData}
              testQuestionLoading={testQuestionLoading}
              moveCard={moveCard}
              findCard={findCard}
              cardOrder={cardOrder}
            />
          ))}
        </div>
      </div>
    </Content>
  );
};
