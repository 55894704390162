import styled from "styled-components";
import { Colors } from "../../constants";
import { Input } from "antd";

const { TextArea } = Input;

export const RadioBlock = styled.div`
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  display: block;
  margin-top: 8px;
  border: 1px solid ${Colors.gray_8};
`;

export const CheckboxBlock = styled.div`
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  display: block;
  margin-top: 8px;
  border: 2px solid ${Colors.gray_7};
`;

export const StyledInput = styled(TextArea)`
  margin-left: 8px;
  padding-left: 0;

  &:hover {
    border-bottom: 1px solid ${Colors.gray_7};
  }

  &:focus {
    border-bottom: 1px solid ${Colors.blue_8};
  }
`;

interface StyledQuestionNameInputProps {
  $isBackground?: boolean;
}

export const StyledQuestionNameInput = styled(
  TextArea
)<StyledQuestionNameInputProps>`
  padding-left: 0;
  background-color: ${({ $isBackground = true }): string => {
    return $isBackground ? Colors.gray_3 : "unset";
  }};
  border-bottom: ${({ $isBackground = true }): string => {
    return $isBackground ? `1px solid ${Colors.gray_7}` : "unset";
  }};

  &:focus {
    background-color: ${({ $isBackground = true }): string => {
      return $isBackground ? Colors.gray_3 : "unset";
    }};
    border-bottom: 1px solid ${Colors.blue_8};
  }

  &:hover {
    background-color: ${({ $isBackground = true }): string => {
      return $isBackground ? Colors.gray_3 : "unset";
    }};
    border-bottom: 1px solid ${Colors.blue_8};
  }

  }
`;

export const TextAnswerWrapper = styled.div`
  border-bottom: 1px dotted ${Colors.gray_7};
  width: 50%;
`;

export const CorrectText = styled.span`
  color: ${Colors.green_5};
`;

export const RawAnswerText = styled.p`
  height: 32px;
  display: inline-block;
  vertical-align: middle;
  line-height: 32px;
  margin-left: 8px;
`;

interface CorrectAnswerWrapperProps {
  $isCorrect?: boolean;
}

export const CorrectAnswerWrapper = styled.div<CorrectAnswerWrapperProps>`
  display: flex;
  width: 100%;

  background-color: ${({ $isCorrect = false }): string => {
    return $isCorrect ? Colors.green_2 : "unset";
  }};

  &:hover {
    background-color: ${({ $isCorrect = false }): string => {
      return $isCorrect ? Colors.green_2 : Colors.green_1;
    }};
  }
`;
