export enum Routes {
  LOGIN = "/login/",
  OAUTH_REDIRECT = "/get_info/",
  LOGOUT = "/logout/",
  REGISTRATION = "/registration/",
  MAIN = "/main/",
  CONSTRUCTOR = "/constructor/:id",
  CONSTRUCTOR_NEW = "/constructor/new/",
  CONSTRUCTOR_PREFIX = "/constructor/",
  START_TEST = "/run-test/:id",
  START_TEST_PREFIX = "/run-test/",
  API_VERSION = "/v1.0/",
}
