import React, { useCallback, useState, useEffect } from "react";
import { CardTestItem } from "../../pages";
import { useMutation, useQuery } from "react-query";
import {
  getNumberTypeByQuestionType,
  IQuestion,
  IQuestionTypes,
} from "../Question/types";
import { CardType } from "../QuestionCard";
import { RunQuestionCard } from "./RunQuestionCard";
import {
  Colors,
  getCoreUserQuestionsAPI,
  getDownloadTestDOCX,
  Routes,
} from "../../constants";
import { Button, Form, message, Modal } from "antd";
import { antIconStyles, noop } from "../../utils";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { currentTestHash } from "../../recoil/selectors";
import {
  createUserAnswerRequest,
  endSessionRequest
} from "../../api/test";
import { DoubleUserAnswer, UserAnswer } from "./types";
import { FileWordOutlined } from "@ant-design/icons/lib/icons";
import { getBaseUrl } from "../../utils/getEnv";

interface TestQuestionsProps {
  data: CardTestItem;
  sessionHash: string;
}

export const TestQuestions = ({
  data,
  sessionHash,
}: TestQuestionsProps): JSX.Element => {
  const [currentSelectedCard, setCurrentSelectedCard] = useState<number>(-1);
  const history = useHistory();
  const location = useLocation();
  const testHash = useRecoilValue(currentTestHash);

  const queryParams = new URLSearchParams(location.search);
  const childId = queryParams.get("question_child_id");
  const userId = queryParams.get("question_user_id");
  const params = Array.from(queryParams)
    .filter((x) => x[0].startsWith("question_"))
    .map((x) => ({ name: x[0].replace("question_", ""), value: x[1] }));

  const [form] = Form.useForm();
  useEffect(() => {
    if (params.length > 0) {
      form.setFields(params);
    }
  }, [form, params]);

  const { data: questionData } = useQuery<IQuestion[]>(
    getCoreUserQuestionsAPI(testHash),
    {
      enabled: data?.id != null && data?.id !== 0,
    }
  );

  const endSessionMutation = useMutation(async () => {
    return endSessionRequest(sessionHash);
  });

  const endKinderGardenSessionMutation = useMutation(
    async (sessionData: DoubleUserAnswer) => {
      createUserAnswerRequest(
        sessionData.child_answer,
        testHash,
        questionChildId?.id ?? 0
      )
        .then(() => {
          createUserAnswerRequest(
            sessionData.user_answer,
            testHash,
            questionUserId?.id ?? 0
          )
            .then(() => {
              return endSessionRequest(sessionHash);
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => console.log(e));
    }
  );

  const leaveTest = useCallback(() => {
    history.push("/");
  }, [history]);

  const UploadDOCX = useCallback((element, e) => {
    window.open(
      `${getBaseUrl()}` + Routes.API_VERSION + getDownloadTestDOCX(element.hash)
    );
  }, []);

  const onEndTest = useCallback(async () => {
    // TODO refactor to apply all parameters
    if (questionChildId && questionUserId) {
      const childIdAnswer: Record<string, any> = {
        [questionChildId.type]: childId,
      };
      const userIdAnswer: Record<string, any> = {
        [questionUserId.type]: userId,
      };

      const childIdNewData: UserAnswer = {
        session_hash: sessionHash,
        type: questionChildId.type,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        answer: childIdAnswer,
      };
      const userIdNewData: UserAnswer = {
        session_hash: sessionHash,
        type: questionUserId.type,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        answer: userIdAnswer,
      };
      const sessionNewData = {
        user_answer: userIdNewData,
        child_answer: childIdNewData,
      };
      if (childId && questionChildId && userId && questionUserId) {
        await endKinderGardenSessionMutation.mutate(sessionNewData);
      }
    } else {
      await endSessionMutation.mutate();
    }

    Modal.info({
      title: "Вы прошли тест",
      okText: "Перейти на главную страницу",
      width: 500,
      onOk() {
        leaveTest();
      },
    });
    message.success("Ответы сохранены и отправлены");
  }, [
    endSessionMutation,
    endKinderGardenSessionMutation,
    leaveTest,
    childId,
    sessionHash,
  ]);

  const questions = (questionData ?? []).filter(
    (question) =>
      question.type !== getNumberTypeByQuestionType(IQuestionTypes.SYSTEM)
  );

  const sysQuestions = (questionData ?? []).filter(
    (question) =>
      question.type === getNumberTypeByQuestionType(IQuestionTypes.SYSTEM)
  );

  const questionChildId = sysQuestions.find((e) => e.name === "child_id");
  const questionUserId = sysQuestions.find((e) => e.name === "user_id");

  return (
    <div className='w-1/2'>
      <RunQuestionCard
        type={CardType.TITLE}
        testData={data}
        onCardClick={noop}
        isActive={false}
        sessionHash={sessionHash}
        form={form}
      />
      <Form
        scrollToFirstError={true}
        form={form}
        name={"question_form"}
        layout={"vertical"}
        initialValues={{ remember: true }}
        autoComplete='off'
        onFinish={onEndTest}
        onFinishFailed={console.log}
        requiredMark={true}
      >
        <div>
          {questions.map((question, idx) => (
            <RunQuestionCard
              type={CardType.QUESTION}
              key={question.id}
              isActive={currentSelectedCard === idx}
              question={question}
              onCardClick={() => setCurrentSelectedCard(idx)}
              testData={data}
              sessionHash={sessionHash}
              form={form}
            />
          ))}
        </div>
        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            className='login-form-button'
            style={{ backgroundColor: Colors.blue_8 }}
          >
            Отправить
          </Button>
          <Button
            className={"inline"}
            style={{ width: "45%", marginLeft: "10%" }}
            icon={<FileWordOutlined style={antIconStyles} />}
            onClick={(e) => UploadDOCX(data, e)}
          >
            Скачать тест в формате .docx
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
