import React, { useCallback, useState } from "react";
import { Button, Form, Input } from "antd";
import { makeRequiredFormFieldRule } from "../../constants/rules";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { FormLabel } from "./styles";
import { useTranslation } from "react-i18next";
import { Colors } from "../../constants";
import { getBaseUrl, getUniEnvId } from "../../utils/getEnv";
import "./style.css";

export const LoginForm = (): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();
  const [formErrors, setFormErrors] = useState<string>();
  const [buttonLoading, setButtonLoading] = useState(false);
  const changeLoading = useCallback((newLoadingStatus: boolean) => {
    setButtonLoading(newLoadingStatus);
  }, []);
  const redirectToOAuth = () => {
    window.location.href = `https://uenv-core.kpfu.ru/oauth/authorize?response_type=code&client_id=${getUniEnvId()}&redirect_url=http://localhost:3000/get_info/`;
  };

  const onSubmit = useCallback(
    (values) => {
      const config = {
        user: {
          email: values.email,
          password: values.password,
        },
      };
      changeLoading(true);
      axios
        .post(`${getBaseUrl()}/v1.0/auth/login/`, config)
        .then((res) => {
          const data = res.data.user.token;
          localStorage.setItem("access-token", data);
          history.push("/main/");
        })
        .catch((error) => {
          const errorData = error?.response?.data?.user?.message;
          if (errorData?.non_field_errors) {
            setFormErrors(errorData?.non_field_errors?.[0]);
          }
        })
        .finally(() => {
          changeLoading(false);
        });
    },
    [history, changeLoading]
  );

  const validateMessages = {
    required: t("required_field"),
    types: {
      email: t("correct_email"),
      number: t("correct_number"),
    },
  };

  return (
    <Form
      name='normal_login'
      className='login-form'
      initialValues={{ remember: true }}
      onFinish={onSubmit}
      layout='vertical'
      requiredMark={false}
      validateMessages={validateMessages}
    >
      <Form.Item
        name='email'
        rules={[
          makeRequiredFormFieldRule(t("login_page.enter_email")),
          { type: "email" },
        ]}
        label={<FormLabel text={t("login_page.enter_email")} />}
      >
        <Input
          prefix={<UserOutlined className='site-form-item-icon' />}
          placeholder='example@stud.kpfu.ru'
          onChange={() => setFormErrors("")}
        />
      </Form.Item>
      <Form.Item
        name='password'
        rules={[makeRequiredFormFieldRule(t("login_page.enter_password"))]}
        label={<FormLabel text={t("login_page.enter_password")} />}
      >
        <Input
          prefix={<LockOutlined className='site-form-item-icon' />}
          type='password'
          placeholder={t("login_page.password")}
          onChange={() => setFormErrors("")}
        />
      </Form.Item>
      {formErrors && (
        <p className='pb-2' style={{ color: Colors.red_6 }}>
          {formErrors}
        </p>
      )}
      <Button
        type='primary'
        htmlType='submit'
        className='login-form-button'
        style={{ margin: "0 30px 0 0" }}
        loading={buttonLoading}
      >
        {t("login_page.login_button")}
      </Button>
      <Button onClick={redirectToOAuth} className='oauth-button'>
        Вход через КФУ
      </Button>
    </Form>
  );
};
