export const getEnv = () => {
  try {
    if (process) {
      return process.env;
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      // return import.meta.env;
    }
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return process.env;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  return process.env;
};

export const getBaseUrl = () => {
  const env = getEnv();
  if (env.REACT_APP_API_URL) {
    return env.REACT_APP_API_URL;
  } else if (env.VITE_API_URL) {
    return env.VITE_API_URL;
  }
  return "";
};

export const getUniEnvId = () => {
  const env = getEnv();
  console.log(env);
  if (env.REACT_APP_UNIENV_CLIENT_ID) {
    return env.REACT_APP_UNIENV_CLIENT_ID;
  }
  return "";
};
