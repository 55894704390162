import React, { useMemo } from "react";
import { StatisticsCard } from "./StatisticsCard";
import { useTranslation } from "react-i18next";
import { Bar } from "@ant-design/plots";
import { Colors, getStatisticsQuestionAnswersAPI } from "../../constants";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { currentTestValue } from "../../recoil/selectors";
import { useLocation } from "react-router-dom";
import { AnswerResponseStat } from "../Answer/types";
import { EmptyChartWrapper } from "./EmptyChartWrapper";

export const QuestionBarCard = (): JSX.Element => {
  const { t } = useTranslation();
  const correctSymbol = "\u2713";
  const currentTest = useRecoilValue(currentTestValue);
  const location = useLocation();
  const locationQuestionId = location.hash.split("=")[1];
  const currentQuestion =
    locationQuestionId != null
      ? +locationQuestionId
      : currentTest.question_order?.[0];
  const { data, isLoading } = useQuery<AnswerResponseStat[]>(
    getStatisticsQuestionAnswersAPI(currentTest.hash, +(currentQuestion ?? 0)),
    {
      enabled: currentTest.hash.length > 0 || currentQuestion != null,
    }
  );

  const barChartData = useMemo(() => {
    if (!data) return [];
    return data.map((value) => {
      const label = value.is_correct
        ? correctSymbol + value.value
        : value.value;
      return {
        answer: label,
        amount: value.answers_count ?? 0,
      };
    });
  }, [data]);

  return (
    <StatisticsCard
      cardTitle={t("correct_answers")}
      bodyStyle={{ textAlign: "center" }}
    >
      <EmptyChartWrapper isEmpty={data?.length === 0} isLoading={isLoading}>
        <Bar
          data={barChartData}
          xField='amount'
          yField='answer'
          height={300}
          color={(v) => {
            if (v?.answer?.includes(correctSymbol)) {
              return Colors.green_5;
            }
            return Colors.blue_7;
          }}
          yAxis={{
            label: {
              autoEllipsis: true,
              autoHide: true,
              style: {
                fontSize: 14,
              },
            },
            verticalLimitLength: 200,
          }}
          xAxis={{
            label: {
              style: {
                fontSize: 14,
              },
            },
          }}
          label={{
            labelLine: true,
          }}
          tooltip={false}
          meta={{
            amount: {
              alias: "Количество ответов",
            },
            answer: {
              alias: "Номер ответа",
            },
          }}
        />
      </EmptyChartWrapper>
    </StatisticsCard>
  );
};
