import {IQuestion} from "../components/Question/types";
import {Colors} from "../constants";
import React from "react";

export const renderQuestionName = (question?: IQuestion): JSX.Element[] => {
    if (!question) return [];
    const parts = question.name.split("\n");
    const renderedNameParts = parts.map((part) => (
        <div className='font-semibold' key={part}>{part}</div>
    ));
    if (question.is_required) {
        renderedNameParts[renderedNameParts.length - 1] = (
            <div className='font-semibold' key="required">
                <div
                    style={{
                        display: "inline-block",
                    }}
                    key="last part"
                >
                    {parts[parts.length - 1]}
                </div>
                <div
                    style={{
                        color: Colors.red_8,
                        display: "inline-block",
                        fontSize: 18,
                        marginLeft: "3px",
                    }}
                    key="asterics"
                >
                    *
                </div>
            </div>);
    }
    return renderedNameParts;
}
