import React from "react";
import logo from "../../images/kfu_logo_white_full.svg";
import { StyledAntHeader } from "./styles";

export const PageHeader = (): JSX.Element => {
  return (
    <>
      <StyledAntHeader className='flex flex-row'>
        <img
          className='my-1 border-r pr-2'
          src={logo}
          alt='Казанский федеральный университет'
        />
        <span className='pl-2 text-lg'>
          <p className='pt-1.5 text-white'>Система</p>
          <p className='-mt-1.5 text-white'>тестирования</p>
        </span>
      </StyledAntHeader>
    </>
  );
};
