import React from "react";
import { StatisticsCard } from "./StatisticsCard";
import { TestInfoBlock } from "./TestInfoBlock";
import { useQuery } from "react-query";
import {
  getAnalyticsSummaryAPI,
  getStatisticsTestAnswersAPI,
  getQuestionsListAPI,
} from "../../constants";
import { useRecoilValue } from "recoil";
import { currentTestHash, currentTestValue } from "../../recoil/selectors";
import { TestAnswersStatistics } from "./types";
import { EmptyChartWrapper } from "./EmptyChartWrapper";
import { Card, Col, Divider, Row, Spin, Statistic, Table } from "antd";
import { Column, Line, LineConfig } from "@ant-design/charts";

export const TestStatisticsCard = (): JSX.Element => {
  const currentTest = useRecoilValue(currentTestValue);
  const testHash = useRecoilValue(currentTestHash);

  const { data: testAnswersData, isLoading: testAnswersLoading } =
    useQuery<TestAnswersStatistics>(
      getStatisticsTestAnswersAPI(currentTest.hash),
      {
        enabled: currentTest.hash.length > 0,
      }
    );

  const { data: analyticsData, isLoading: analyticsLoading } = useQuery<
    Record<string, any>
  >(getAnalyticsSummaryAPI(testHash));

  const { data: questionsSummary, isLoading: questionsSummaryLoading } =
    useQuery<Record<string, any>>(getQuestionsListAPI(testHash));

  const analyticsDataColumns = [
    {
      title: "Текст вопроса",
      dataIndex: "question_text",
      key: "question_text",
    },
    {
      title: "ID вопроса",
      dataIndex: "question_id",
      key: "question_id",
    },
    {
      title: "Тип вопроса",
      dataIndex: "question_type",
      key: "question_type",
      render: (value: any) => {
        switch (value) {
          case "Multiple":
            return "Множественный выбор";
          case "Single":
            return "Одиночный выбор";
          case "Paragraph":
            return "Текст(Абзац)";
          case "Text":
            return "Текст(Строка)";
          case "Sequence":
            return "Последовательность";
          case "Matching":
            return "Соответствие";
          case "System":
            return "Системный";
          default:
            return value;
        }
      },
    },
  ];

  const LineAnalyticsChart = () => {
    const data = analyticsData
      ? Object.entries(analyticsData).map(([key, value]) => ({
          questions: key,
          countAnswers: Object.values(value)
            .map(Number)
            .reduce((temporarySum: number, a: number) => temporarySum + a, 0),
        }))
      : [];

    const config: LineConfig = {
      data,
      padding: "auto",
      xField: "questions",
      yField: "countAnswers",
      xAxis: {
        tickCount: 5,
        title: {
          text: "Вопросы",
        },
      },
      yAxis: {
        title: {
          text: "Количество ответов",
        },
      },
      meta: {
        questions: { alias: "Вопросы" },
        countAnswers: { alias: "Количество ответов" },
      },
      renderer: "svg",
    };

    return <Line {...config} />;
  };

  const DataAnalyticsTable = () =>
    questionsSummaryLoading ? (
      <Spin />
    ) : (
      <div style={{ paddingTop: "15px" }}>
        <Table
          dataSource={questionsSummary ? questionsSummary.questions : []}
          columns={analyticsDataColumns}
          rowKey='question_id'
        />
      </div>
    );

  return (
    <StatisticsCard
      cardTitle={"Статистика теста"}
      bodyStyle={{ textAlign: "center" }}
    >
      <div className='flex space-x-4'>
        <TestInfoBlock
          primaryText={"Респондентов"}
          secondaryText={`${testAnswersData?.sessions ?? 0}`}
        />
        <TestInfoBlock
          primaryText={"Макс баллов"}
          secondaryText={`${testAnswersData?.test_points ?? 0}`}
        />
      </div>
      <p className='text-2xl font-semibold my-4 text-center'>
        Распределение баллов
      </p>
      <EmptyChartWrapper
        isEmpty={testAnswersData?.sessions_distribution?.length === 0}
        isLoading={testAnswersLoading}
        description={"Нет ответов на тест"}
      >
        <Column
          xField='score'
          yField='count'
          data={testAnswersData?.sessions_distribution ?? []}
          height={250}
          yAxis={{
            label: {
              autoEllipsis: true,
              autoHide: true,
              style: {
                fontSize: 14,
              },
            },
            title: {
              text: "Респондентов",
            },
          }}
          xAxis={{
            label: {
              autoEllipsis: true,
              autoHide: true,
              style: {
                fontSize: 14,
              },
            },
            title: {
              text: "Получено баллов",
            },
          }}
          meta={{
            count: {
              alias: "Количество респондентов",
            },
            score: {
              alias: "Получено баллов",
            },
          }}
          renderer='svg'
        />
      </EmptyChartWrapper>

      <Divider style={{ fontSize: "1.5rem" }}>Аналитика</Divider>

      {analyticsLoading ? (
        <Spin />
      ) : (
        <>
          <div
            style={{
              background: "#ECECEC",
              padding: "10px",
              marginTop: "20px",
            }}
          >
            <Row gutter={16}>
              <Col span={8}>
                <Card>
                  <Statistic
                    title='Всего вопросов в тесте'
                    value={Object.keys(analyticsData ?? {}).length}
                    valueStyle={{ color: "#0050B3" }}
                  />
                </Card>
              </Col>
              <Col span={8}>
                <Card>
                  <Statistic
                    title='Название теста'
                    value={
                      questionsSummary
                        ? questionsSummary.test.name
                        : "Тест без названия"
                    }
                    valueStyle={{ color: "#0050B3" }}
                  />
                </Card>
              </Col>
              <Col span={8}>
                <Card>
                  <Statistic
                    title='Всего ответивших'
                    value={testAnswersData?.sessions ?? 0}
                    valueStyle={{ color: "#0050B3" }}
                  />
                </Card>
              </Col>
            </Row>
          </div>

          <div
            style={{
              paddingTop: "10px",
            }}
          >
            <LineAnalyticsChart />
          </div>

          <DataAnalyticsTable />
        </>
      )}
    </StatisticsCard>
  );
};
