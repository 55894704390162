import { CSSProperties } from "react";
import { Colors } from "../constants";

export const antIconStyles: CSSProperties = {
  verticalAlign: "0.125rem",
};

export const defaultIconSize: CSSProperties = {
  fontSize: 18,
};

export const constructorMenuButtonStyles: CSSProperties = {
  ...antIconStyles,
  fontSize: 22,
  color: Colors.gray_8,
};
