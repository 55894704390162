import React, { CSSProperties } from "react";
import { Card, Divider } from "antd";

interface StatisticsCardProps {
  cardTitle?: React.ReactNode | string;
  children?: React.ReactNode;
  bodyStyle?: CSSProperties;
}

export const StatisticsCard = ({
  cardTitle,
  children,
  bodyStyle,
}: StatisticsCardProps): JSX.Element => {
  return (
    <Card
      bodyStyle={bodyStyle}
      className={`w-full rounded-b-lg cursor-pointer shadow-md hover:shadow-xl border-0 my-4`}
    >
      {cardTitle && typeof cardTitle == "string" ? (
        <>
          <p className='text-2xl font-medium'>{cardTitle}</p>
          <Divider className='my-2 border-t-2 border-gray-400' />
        </>
      ) : (
        <>{cardTitle}</>
      )}
      {children}
    </Card>
  );
};
