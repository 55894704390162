import { CardTestItem } from "../../pages";
import React from "react";
import Title from "antd/lib/typography/Title";
import {renderDescription} from "../../utils/renderDescription";

interface TitleCardProps {
  testData: CardTestItem;
  isActive: boolean;
}

const marginBottomStyle = { marginBottom: 10 };
const smallMarginBottomStyle = { marginBottom: 5 };

export const TitleCard = ({ testData }: TitleCardProps): JSX.Element => {
  return (
    <>
      <Title level={4} style={smallMarginBottomStyle}>
        Название
      </Title>
      <p className={"text-lg"} style={marginBottomStyle}>
        {testData.name}
      </p>
      <Title level={4} style={smallMarginBottomStyle}>
        Описание
      </Title>
      <p className={"text-lg"} style={marginBottomStyle}>
        {renderDescription(testData.description)}
      </p>
    </>
  );
};
