import { makeRequest } from "../api";
import { apiClient } from "../apiClient";
import { CardTestItem } from "../../pages";
import { ResponseResult } from "../types";
import { IQuestion } from "../../components/Question/types";
import {
  getConstrictorTestAPI,
  getCoreCreateQuestionAnswersAPI,
  getCoreQuestionAnswersAPI,
  getCoreQuestionsAPI,
  getCoreUserAnswerAPI,
  getCreateAnswerAPI,
  getCreateQuestionAPI,
  getCreateSessionAPI,
  getCreateTestAPI,
  getEndSessionAPI,
  checkUserAuthAPI, getCoreUserQuestionsAPI,
} from "../../constants";
import { AnswerResponse } from "../../components/Answer/types";
import { Session, UserAnswer } from "../../components/RunTest/types";
import { User } from "../../components/User";

export const createNewTestRequest = (
  newTestData: Partial<CardTestItem>
): Promise<ResponseResult<CardTestItem>> => {
  return makeRequest(() =>
    apiClient.post(getCreateTestAPI(), {
      ...newTestData,
    })
  );
};

export const CheckUserAuth = (): Promise<ResponseResult<User>> => {
  return makeRequest(() => apiClient.post(checkUserAuthAPI(), {}));
};

export const editTestRequest = (
  newTestData: CardTestItem
): Promise<ResponseResult<CardTestItem>> => {
  return makeRequest(() =>
    apiClient.put(getConstrictorTestAPI(newTestData.hash), {
      ...newTestData,
    })
  );
};

export const editTestQuestionRequest = (
  newData: IQuestion,
  testHash: string
): Promise<ResponseResult<IQuestion>> => {
  return makeRequest(() =>
    apiClient.put(getCoreQuestionsAPI(testHash, newData.id), {
      ...newData,
    })
  );
};

export const editTestQuestionAnswerRequest = (
  newData: AnswerResponse,
  testHash: string
): Promise<ResponseResult<AnswerResponse>> => {
  return apiClient.put(getCoreQuestionAnswersAPI(testHash, newData.id), {
    ...newData,
  });
};

export const createTestQuestionAnswerRequest = (
  newData: Partial<AnswerResponse>,
  testHash: string
): Promise<ResponseResult<AnswerResponse>> => {
  return makeRequest(() =>
    apiClient.post(getCoreCreateQuestionAnswersAPI(testHash), {
      ...newData,
    })
  );
};

export const createNewAnswerRequest = (
  newTestData: Partial<AnswerResponse>
): Promise<ResponseResult<AnswerResponse>> => {
  return makeRequest(() =>
    apiClient.post(getCreateAnswerAPI(), {
      ...newTestData,
    })
  );
};

export const createNewQuestionRequest = (
    newQData: Partial<IQuestion>
): Promise<ResponseResult<IQuestion>> => {
  return makeRequest(() =>
    apiClient.post(getCreateQuestionAPI(), {
      ...newQData,
    })
  );
};

export const deleteTestQuestionAnswerRequest = (
  testHash: string,
  answerId: number
): Promise<ResponseResult<AnswerResponse>> => {
  return makeRequest(() =>
    apiClient.delete(getCoreQuestionAnswersAPI(testHash, answerId))
  );
};

export const deleteTestQuestionRequest = (
  testHash: string,
  questionId: number
): Promise<ResponseResult<IQuestion>> => {
  return makeRequest(() =>
    apiClient.delete(getCoreQuestionsAPI(testHash, questionId))
  );
};

export const createSessionRequest = (
  test_hash: string
): Promise<ResponseResult<Session>> => {
  return makeRequest(() =>
    apiClient.post(getCreateSessionAPI(), {
      test_hash,
    })
  );
};

export const createUserAnswerRequest = (
  userAnswerData: UserAnswer,
  testHash: string,
  questionId: number
): Promise<ResponseResult<UserAnswer>> => {
  return makeRequest(() =>
    apiClient.post(getCoreUserAnswerAPI(testHash, questionId), {
      ...userAnswerData,
    })
  );
};

export const endSessionRequest = (
  session_hash: string
): Promise<ResponseResult<Session>> => {
  return makeRequest(() =>
    apiClient.put(getEndSessionAPI(), {
      session_hash,
    })
  );
};