import {
  getQuestionTypeByNumberType,
  IQuestionTypes,
} from "../../Question/types";
import { CheckboxBlock, RadioBlock } from "../styles";
import { AddNewAnswerInput } from "../AddNewAnswerInput";
import React from "react";
import { AnswersProps } from "../Answers";

interface CreateAnswerProps extends AnswersProps {
  onCreatedAnswer: (value: number) => void;
}

export const CreateAnswer = ({
  question,
  onCreatedAnswer,
  isChangeCorrectAnswers,
}: CreateAnswerProps): JSX.Element => {
  const questionType = getQuestionTypeByNumberType(question.type);
  if (
    (questionType !== IQuestionTypes.SINGLE &&
      questionType !== IQuestionTypes.MULTIPLE) ||
    isChangeCorrectAnswers
  ) {
    return <></>;
  }
  return (
    <>
      <div className='flex align-center m-2'>
        {questionType === IQuestionTypes.SINGLE ? (
          <RadioBlock className='flex-shrink-0' />
        ) : (
          <CheckboxBlock />
        )}
        <AddNewAnswerInput
          questionId={question.id}
          type={question.type}
          nextAnswerNumber={question.answers?.length ?? 0}
          onCreatedAnswer={onCreatedAnswer}
        />
      </div>
    </>
  );
};
