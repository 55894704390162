// eslint-disable-next-line import/no-named-as-default
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "ru",
    detection: {
      order: ["querystring", "cookie"],
      caches: ["cookie"],
    },
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
