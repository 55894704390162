import React from "react";
import { Colors } from "../../constants";
import { CardType } from "../QuestionCard";
import { EditQuestionCard } from "./EditQuestionCard";
import {Card} from "antd";
import { CardTestItem } from "../../pages";
import { IQuestion } from "../Question/types";
import { TitleCard } from "./TitleCard";

interface RunQuestionCardProps {
  isActive: boolean;
  type: CardType;
  onCardClick: () => void;
  testData: CardTestItem;
  question?: IQuestion;
  sessionHash: string;
  form: any,
}

export const RunQuestionCard = ({
  isActive,
  type,
  onCardClick,
  testData,
  question,
  sessionHash,
  form,
}: RunQuestionCardProps): JSX.Element => {
  const isActiveStyles = isActive ? "border-b-8" : "";
  const titleStyles = type === CardType.TITLE ? "border-t-8" : "";
  return (
    <>
      <Card
        className={`w-full rounded-lg cursor-pointer shadow-md hover:shadow-xl ${isActiveStyles} ${titleStyles}`}
        onClick={onCardClick}
        style={{
          borderColor: Colors.blue_8,
          marginBottom: 16,
        }}
      >
        {type === CardType.TITLE ? (
          <TitleCard testData={testData} isActive={isActive} />
        ) : (
          <EditQuestionCard
            question={question}
            testData={testData}
            isActive={isActive}
            sessionHash={sessionHash}
            form={form}
          />
        )}
      </Card>
    </>
  );
};
