import React from "react";
import { Empty, Spin } from "antd";

interface EmptyChartWrapperProps {
  children: React.ReactNode;
  isEmpty: boolean;
  isLoading: boolean;
  description?: string;
}

export const EmptyChartWrapper = ({
  children,
  isEmpty,
  isLoading,
  description,
}: EmptyChartWrapperProps): JSX.Element => {
  return (
    <>
      {isLoading ? (
        <div className='flex justify-center'>
          <Spin size='large' />
        </div>
      ) : isEmpty ? (
        <Empty description={description ?? "Нет ответов на данный вопрос"} />
      ) : (
        children
      )}
    </>
  );
};
