import { AxiosResponse } from "axios";
import { ResponseResult } from "./types";
import { Routes } from "../constants";

function helpResponseDataTransformer<TData>(
  responseData: any,
  status?: number
): ResponseResult<TData> {
  const success = responseData.success;
  const data = responseData.data;
  const message = responseData.message;
  const code = status ?? 200;

  return {
    data,
    message,
    success,
    code,
  };
}

export async function makeRequest<TData>(
  requester: () => Promise<AxiosResponse<any>>
): Promise<ResponseResult<TData>> {
  try {
    const result = await requester();
    return helpResponseDataTransformer<TData>(result.data, result.status);
  } catch (e) {
    const responseStatus = (e as any).response?.status;
    if (responseStatus === 401 || responseStatus === 403) {
      window.location.replace(Routes.LOGIN);
    }
    return { success: false, data: null };
  }
}

export const isSuccessfulAndWithData = (
  response: ResponseResult<any>
): boolean => {
  return response && response.data != null && response.success;
};
