import React, { useCallback, useRef, useState } from "react";
import { CorrectText } from "../styles";
import { IQuestion } from "../../Question/types";
import { TextAnswerInput } from "./TextAnswerInput";
import { AddNewAnswerInput } from "../AddNewAnswerInput";
import { DeleteAnswer } from "../DeleteAnswer";

interface TextareaAnswerProps {
  question: IQuestion;
  isChangeCorrectAnswers: boolean;
}

export const TextareaAnswer = ({
  question,
  isChangeCorrectAnswers,
}: TextareaAnswerProps): JSX.Element => {
  const answers = question.answers ?? [];
  const createdInputRef = useRef<any>(null);
  const [lastCreatedId, setLastCreatedId] = useState();

  const onCreatedAnswer = useCallback((answerId) => {
    setLastCreatedId(answerId);
  }, []);

  return (
    <>
      {isChangeCorrectAnswers ? (
        <>
          {answers.map((answer) => {
            return (
              <div className='flex' key={`ans-wrapper-${answer.id}`}>
                <TextAnswerInput
                  key={`ans-${answer.id}`}
                  answer={answer}
                  inputRef={
                    lastCreatedId === answer.id ? createdInputRef : null
                  }
                />
                <DeleteAnswer answerId={answer.id} />
              </div>
            );
          })}
          <AddNewAnswerInput
            questionId={question.id}
            type={question.type}
            nextAnswerNumber={question.answers?.length ?? 0}
            onCreatedAnswer={onCreatedAnswer}
            createCorrect
          />
        </>
      ) : (
        <div>
          <CorrectText>Правильный ответ: </CorrectText>
          {answers.map((i) => i.value).join(", ")}
        </div>
      )}
    </>
  );
};
