import React from "react";
import { TestStatisticsCard } from "./TestStatisticsCard";

export const TestSection = (): JSX.Element => {
  return (
    <>
      <TestStatisticsCard />
      {/*<StatisticsCard cardTitle={"Проблемные вопросы"}>*/}
      {/*    {*/}
      {/*        problemQuestions.map((item) => {*/}
      {/*            return (*/}
      {/*                <div className="bg-gray-100 flex my-2 p-2" key={item.question}>*/}
      {/*                    <div className="flex-1">*/}
      {/*                        <p>{item.question}</p>*/}
      {/*                    </div>*/}
      {/*                    <div>*/}
      {/*                        {*/}
      {/*                            item.problems.map((problem) =>*/}
      {/*                                <ProblemTag type={problem}*/}
      {/*                                            key={item.question + "_" + problem}/>*/}
      {/*                            )*/}
      {/*                        }*/}
      {/*                    </div>*/}
      {/*                </div>*/}
      {/*            )*/}
      {/*        })*/}
      {/*    }*/}

      {/*</StatisticsCard>*/}
    </>
  );
};
