import React, { FC } from "react";
import loginImage from "../../images/LoginImage.svg";
import { LoginAndRegistrationTabs } from "../../components/LoginAndRegistrationTabs";
import { useTranslation } from "react-i18next";

export const Login: FC = () => {
  const { t } = useTranslation();

  return (
    <div className='flex justify-evenly'>
      <div id='imageSection' className=''>
        <img
          src={loginImage}
          alt={`${t("login_page.login")}`}
          className='p-20'
        />
      </div>
      <div id='loginForm' className='w-1/3'>
        <div className='w-1/2'>
          <LoginAndRegistrationTabs />
        </div>
      </div>
    </div>
  );
};
