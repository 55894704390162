import React, { useEffect, useState, FC } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

export const UserInfoPage: FC = () => {
  const [, setUserData] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const getUrlParameter = (name: string) => {
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
      const results = regex.exec(window.location.search);
      return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
    };

    const code = getUrlParameter("code");
    console.log(code);

    if (code) {
      axios
        .get(
          `http://127.0.0.1:8000/v1.0/auth/login/unienv_auth_callback/?code=${code}`
        )
        .then((response) => {
          setUserData(response.data); // Сохранение данных о пользователе
          console.log("Данные о пользователе получены:", response.data.token);
          localStorage.setItem("access-token", response.data.token);
          history.push("/main/");
        })
        .catch((error) => {
          console.error("Ошибка при выполнении запроса:", error);
        });
    }
  }, [history]);

  // Возвращаем JSX компонента
  return (
    <div>
      <h1>Ожидание информации о пользователе</h1>
    </div>
  );
};
