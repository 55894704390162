import React from "react";
import { StyledContent } from "../../pages";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";

interface ContentProps {
  children?: React.ReactNode;
  isLoading: boolean;
}

export const Content = ({ children, isLoading }: ContentProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledContent>
      {isLoading ? (
        <div className='flex justify-center py-5'>
          <div>
            <span className='px-3 text-primary text-3xl'>{t("loading")}</span>
            <Spin size='large' />
          </div>
        </div>
      ) : (
        <div
          className='flex justify-center'
          style={{ padding: 24, paddingTop: 0 }}
        >
          <div className='w-1/2'>{children}</div>
        </div>
      )}
    </StyledContent>
  );
};
