import React, { useCallback, useRef, useState } from "react";
import { AnswerResponse } from "../Answer/types";
import {
  getQuestionTypeByNumberType,
  IQuestion,
  IQuestionTypes,
} from "../Question/types";
import { useMutation } from "react-query";
import { RadioAnswer } from "./AnswerTypes/RadioAnswer";
import { CheckboxAnswer } from "./AnswerTypes/CheckboxAnswer";
import { CreateAnswer } from "./AnswerTypes/CreateAnswer";
import { TextAnswer } from "./AnswerTypes/TextAnswer";
import { TextareaAnswer } from "./AnswerTypes/TextareaAnswer";
import { useRecoilValue } from "recoil";
import { currentTestHash } from "../../recoil/selectors";
import { editTestQuestionAnswerRequest } from "../../api/test";
import { invalidateTestQuestionRequest } from "../../utils/invalidation";

export interface AnswersProps {
  isActive: boolean;
  question: IQuestion;
  isChangeCorrectAnswers: boolean;
}

export const Answers = ({
  isActive,
  question,
  isChangeCorrectAnswers,
}: AnswersProps): JSX.Element => {
  const createdInputRef = useRef<any>(null);
  const [lastCreatedId, setLastCreatedId] = useState();
  const testHash = useRecoilValue(currentTestHash);

  const onCreatedAnswer = useCallback((answerId) => {
    setLastCreatedId(answerId);
  }, []);

  const updateAnswerMutation = useMutation(async (newData: AnswerResponse) => {
    return editTestQuestionAnswerRequest(newData, testHash)
      .then(() => {
        invalidateTestQuestionRequest(testHash);
      })
      .catch((err) => console.log(err));
  });

  const updateAnswerValue = useCallback(
    (newAnswer: AnswerResponse) => {
      updateAnswerMutation.mutate(newAnswer);
    },
    [updateAnswerMutation]
  );

  const drawConstructorAnswers = () => {
    switch (getQuestionTypeByNumberType(question.type)) {
      case IQuestionTypes.TEXT:
        return (
          <TextAnswer
            isChangeCorrectAnswers={isChangeCorrectAnswers}
            question={question}
          />
        );
      case IQuestionTypes.SINGLE:
        return (
          <>
            {question.answers?.map((answer) => {
              return (
                <RadioAnswer
                  answer={answer}
                  isActive={isActive}
                  key={`answer_radio_${answer.id}_${answer.value}`}
                  inputRef={
                    lastCreatedId === answer.id ? createdInputRef : null
                  }
                  isChangeCorrectAnswers={isChangeCorrectAnswers}
                  updateAnswerValue={updateAnswerValue}
                />
              );
            })}
          </>
        );

      case IQuestionTypes.MULTIPLE:
        return (
          <>
            {question?.answers?.map((answer) => {
              return (
                <CheckboxAnswer
                  answer={answer}
                  isActive={isActive}
                  key={`answer_checkbox_${answer.id}_${answer.value}`}
                  inputRef={
                    lastCreatedId === answer.id ? createdInputRef : null
                  }
                  isChangeCorrectAnswers={isChangeCorrectAnswers}
                  updateAnswerValue={updateAnswerValue}
                />
              );
            })}
          </>
        );
      case IQuestionTypes.PARAGRAPH:
        return (
          <TextareaAnswer
            isChangeCorrectAnswers={isChangeCorrectAnswers}
            question={question}
          />
        );
     case IQuestionTypes.SYSTEM:
        return "";
      default:
        return <p>Not implemented</p>;
    }
  };
  return (
    <>
      {drawConstructorAnswers()}
      {isActive && (
        <CreateAnswer
          isActive={isActive}
          question={question}
          onCreatedAnswer={onCreatedAnswer}
          isChangeCorrectAnswers={isChangeCorrectAnswers}
        />
      )}
    </>
  );
};
