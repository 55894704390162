import { queryClient } from "../api/queryClient";
import {
  getConstrictorTestAPI,
  getConstrictorTestQuestionsAPI,
  getCoreQuestionsAPI,
} from "../constants";

export const invalidateTestRequest = (testHash: string): void => {
  queryClient.invalidateQueries(getConstrictorTestAPI(testHash));
};

export const invalidateTestQuestionRequest = (testHash: string): void => {
  queryClient.invalidateQueries(getConstrictorTestQuestionsAPI(testHash));
};

export const invalidateQuestionRequest = (
  testHash: string,
  id: number
): void => {
  queryClient.invalidateQueries(getCoreQuestionsAPI(testHash, id));
};
