import React from "react";
import Title from "antd/lib/typography/Title";

interface FormLabelProps {
  text: string;
}

export const FormLabel = ({ text }: FormLabelProps): JSX.Element => {
  return (
    <Title
      level={5}
      style={{
        marginBottom: "unset",
        fontWeight: "unset",
      }}
    >
      {text}
    </Title>
  );
};
