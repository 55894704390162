import React, { Suspense, useEffect } from "react";
import { Login, Main, Registration, UserInfoPage } from "./pages";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { Routes } from "./constants";
import "antd/dist/antd.less";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./api/queryClient";
import { TestConstructorPage } from "./pages/TestConstructorPage";
import { Menu } from "./components/Menu";
import { StartTestPage } from "./pages/StartTestPage";
import { PageHeader } from "./components/Header";
import { PageLayout } from "./components/Layout";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { RecoilRoot } from "recoil";

export const App = (): JSX.Element => {
  useEffect(() => {
    document.title = "Система тестирования";
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <DndProvider backend={HTML5Backend}>
          <Router>
            <Suspense fallback='loading'>
              <PageLayout
                style={{
                  minHeight: "100vh",
                }}
              >
                <PageHeader />
                <PageLayout>
                  <Menu />
                  <Switch>
                    <Route exact path={Routes.LOGIN} component={Login} />
                    <Route
                      exact
                      path={Routes.REGISTRATION}
                      component={Registration}
                    />
                    <Route
                      exact
                      path={Routes.OAUTH_REDIRECT}
                      component={UserInfoPage}
                    />
                    <Route exact path={Routes.MAIN} component={Main} />
                    <Route exact path='/' component={Main} />
                    <Route
                      path={Routes.CONSTRUCTOR}
                      component={TestConstructorPage}
                    />
                    <Route path={Routes.START_TEST} component={StartTestPage} />
                    <Redirect to={Routes.LOGIN} />
                  </Switch>
                </PageLayout>
              </PageLayout>
            </Suspense>
          </Router>
        </DndProvider>
      </RecoilRoot>
    </QueryClientProvider>
  );
};
