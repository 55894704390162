import React from "react";
import { CorrectAnswerWrapper as StyledCorrectAnswerWrapper } from "../styles";

interface CorrectAnswerWrapperProps {
  children: React.ReactNode;
  isCorrect: boolean;
  onClick: () => void;
}

export const CorrectAnswerWrapper = ({
  children,
  isCorrect,
  onClick,
}: CorrectAnswerWrapperProps): JSX.Element => {
  return (
    <StyledCorrectAnswerWrapper
      className='rounded-lg px-2'
      $isCorrect={isCorrect}
      onClick={onClick}
    >
      {children}
    </StyledCorrectAnswerWrapper>
  );
};
