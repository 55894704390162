import React from "react";
import { Tooltip as AntTooltip } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import { LiteralUnion } from "antd/lib/_util/type";
import { PresetColorType } from "antd/lib/_util/colors";
import { Colors } from "../../constants";

interface TooltipProps {
  children: React.ReactNode;
  title: React.ReactNode;
  placement?: TooltipPlacement;
  color?: LiteralUnion<PresetColorType, string>;
}

export const Tooltip = ({
  children,
  title,
  placement = "left",
  color = Colors.gray_8,
}: TooltipProps): JSX.Element => {
  return (
    <AntTooltip title={title} placement={placement} color={color}>
      {children}
    </AntTooltip>
  );
};
