import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { StyledContent, CardTestItem } from "../Main";
import { useQuery } from "react-query";
import { PageHeader } from "../../components/PageHeader";
import { StartTestModal, TestQuestions } from "../../components/RunTest";
import { useRecoilState } from "recoil";
import { currentTest } from "../../recoil";
import { getConstrictorTestRunAPI } from "../../constants";
import { Error404 } from "../../components/Errors/Error404";

export const StartTestPage = (): JSX.Element => {
  const { id: hash } = useParams<{ id: string }>();
  const [, setCurrentTestValue] = useRecoilState(currentTest);

  const [isStartedTest, setIsStartedTest] = useState<boolean>(false);
  const { data, isLoading } = useQuery<CardTestItem>(
    getConstrictorTestRunAPI(hash),
    {
      enabled: hash.length > 0,
      onSuccess: (backendData: CardTestItem) => {
        setCurrentTestValue(backendData);
      },
    }
  );
  const [sessionHash, setSessionHash] = useState<string>("");

  const routes = [
    {
      path: "/",
      breadcrumbName: "Мои тесты",
    },
    {
      path: `/start-test/${hash}/`,
      breadcrumbName: data?.name ?? "",
    },
  ];

  const onClick = useCallback(() => {
    setIsStartedTest(true);
  }, []);

  return (
    <StyledContent>
      <PageHeader
        routes={routes}
        title={`Прохождение теста`}
        isLoading={isLoading}
      />
      <div className='flex justify-center' style={{ padding: 24 }}>
        {!isStartedTest && data && (
          <StartTestModal
            isLoading={isLoading}
            onClick={onClick}
            data={data}
            setSession={setSessionHash}
          />
        )}
        {isStartedTest && data && (
          <TestQuestions data={data} sessionHash={sessionHash} />
        )}
        {/* TODO: Поменять !data на !idSuccess после редактирования src/api/queryClient */}
        {!data && !isLoading && <Error404 errorMessage={"Тест не найден"} />}
      </div>
    </StyledContent>
  );
};
