import React, { useCallback } from "react";
import {
  CheckSquareOutlined,
  CopyOutlined,
  DeleteOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import { antIconStyles } from "../../utils";
import { useTranslation } from "react-i18next";
import { Colors } from "../../constants";
import styled from "styled-components";
import { useMutation } from "react-query";
import { Tooltip } from "../Tooltip";
import { useRecoilValue } from "recoil";
import { currentTestHash } from "../../recoil/selectors";
import { deleteTestQuestionRequest } from "../../api/test";
import { invalidateTestQuestionRequest } from "../../utils/invalidation";

interface CardFooterProps {
  questionId: number;
  onChangeCorrectAnswers: () => void;
  isChangeCorrectAnswers: boolean;
}

export const CardFooter = ({
  questionId,
  onChangeCorrectAnswers,
  isChangeCorrectAnswers,
}: CardFooterProps): JSX.Element => {
  const { t } = useTranslation();
  const testHash = useRecoilValue(currentTestHash);

  const deleteQuestionMutation = useMutation(async () => {
    return deleteTestQuestionRequest(testHash, questionId).then(() => {
      invalidateTestQuestionRequest(testHash);
    });
  });

  const onDeleteQuestion = useCallback(() => {
    deleteQuestionMutation.mutate();
  }, [deleteQuestionMutation]);

  return (
    <div className='pt-2 flex justify-around flex-wrap'>
      {isChangeCorrectAnswers ? (
        <SaveAnswersButton
          icon={<CheckSquareOutlined style={antIconStyles} />}
          className='border-0 rounded-lg'
          onClick={onChangeCorrectAnswers}
        >
          {t("save_answers")}
        </SaveAnswersButton>
      ) : (
        <>
          <AnswerAndPointsButton
            icon={<CheckSquareOutlined style={antIconStyles} />}
            className='border-0 rounded-lg'
            onClick={onChangeCorrectAnswers}
          >
            {t("questions_and_points")}
          </AnswerAndPointsButton>

          <DeleteQuestionButton
            icon={<DeleteOutlined style={antIconStyles} />}
            className='border-0 rounded-lg'
            onClick={onDeleteQuestion}
          >
            {t("delete_question")}
          </DeleteQuestionButton>
          <Tooltip title={t("copy_question")} placement='bottom'>
            <CopyQuestionButton
              icon={<CopyOutlined style={antIconStyles} />}
              className='border-0 rounded-lg'
            />
          </Tooltip>
          <Tooltip title={t("add_description")} placement='bottom'>
            <AddDescriptionQuestionButton
              icon={<FormOutlined style={antIconStyles} />}
              className='border-0 rounded-lg'
            />
          </Tooltip>
        </>
      )}
    </div>
  );
};

export const AnswerAndPointsButton = styled(Button)`
  color: ${Colors.blue_8};
  box-shadow: unset;

  &:hover {
    background-color: ${Colors.blue_1};
  }
`;

const DeleteQuestionButton = styled(Button)`
  color: ${Colors.red_8};
  box-shadow: unset;

  &:hover {
    background-color: ${Colors.red_1};
    color: ${Colors.red_8};
  }
`;

const CopyQuestionButton = styled(Button)`
  color: ${Colors.gray_8};
  box-shadow: unset;

  &:hover {
    background-color: ${Colors.orange_1};
    color: ${Colors.gray_8};
  }
`;

const AddDescriptionQuestionButton = styled(Button)`
  color: ${Colors.gray_8};
  box-shadow: unset;

  &:hover {
    background-color: ${Colors.green_1};
    color: ${Colors.gray_8};
  }
`;

const SaveAnswersButton = styled(Button)`
  color: ${Colors.green_8};
  box-shadow: unset;

  &:hover {
    background-color: ${Colors.green_1};
    color: ${Colors.green_8};
  }
`;
