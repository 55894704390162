import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useMutation } from "react-query";
import { Colors } from "../../constants";
import styled from "styled-components";
import { Tooltip } from "../Tooltip";
import { useTranslation } from "react-i18next";
import { constructorMenuButtonStyles } from "../../utils";
import { useRecoilValue } from "recoil";
import { currentTestHash } from "../../recoil/selectors";
import { deleteTestQuestionAnswerRequest } from "../../api/test";
import { invalidateTestQuestionRequest } from "../../utils/invalidation";

interface DeleteAnswerProps {
  answerId: number;
}

export const DeleteAnswer = ({ answerId }: DeleteAnswerProps): JSX.Element => {
  const { t } = useTranslation();
  const testHash = useRecoilValue(currentTestHash);

  const deleteAnswerMutation = useMutation(async () => {
    return deleteTestQuestionAnswerRequest(testHash, answerId).then(() => {
      invalidateTestQuestionRequest(testHash);
    });
  });

  return (
    <Tooltip placement='right' title={t("delete_answer")}>
      <StyledDeleteButton
        icon={<CloseOutlined style={constructorMenuButtonStyles} />}
        className='border-0 rounded-lg'
        onClick={() => {
          deleteAnswerMutation.mutate();
        }}
      />
    </Tooltip>
  );
};

const StyledDeleteButton = styled(Button)`
  margin: 0 0 0 16px;
  padding: 0 2px;

  &:hover {
    background-color: ${Colors.red_1};
  }
`;
