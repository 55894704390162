import React from "react";
import { StatisticsCard } from "./StatisticsCard";
import { useRecoilValue } from "recoil";
import { currentTestValue } from "../../recoil/selectors";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { getCoreQuestionsAPI } from "../../constants";
import { IQuestion } from "../Question/types";
import { useTranslation } from "react-i18next";
import { QuestionBarCard } from "./QuestionBarCard";

export const QuestionSection = (): JSX.Element => {
  const currentTest = useRecoilValue(currentTestValue);
  const location = useLocation();
  const { t } = useTranslation();

  const locationQuestionId = location.hash.split("=")[1];
  const currentQuestion =
    locationQuestionId != null
      ? +locationQuestionId
      : currentTest.question_order?.[0];
  const { data } = useQuery<IQuestion>(
    getCoreQuestionsAPI(currentTest.hash, +(currentQuestion ?? 0)),
    {
      enabled: currentTest.hash.length > 0 || currentQuestion != null,
    }
  );
  if (!data) {
    return <></>;
  }

  return (
    <>
      <StatisticsCard
        cardTitle={t("question")}
        bodyStyle={{ textAlign: "center" }}
      >
        <p>{data.name}</p>
      </StatisticsCard>

      <QuestionBarCard />

      {/*<StatisticsCard cardTitle={t("guessing_criteria")}>*/}
      {/*  <p className='text-lg'>*/}
      {/*    <span className='text-red-500'>2</span> из 4 ответов правильные*/}
      {/*  </p>*/}
      {/*  <p className='text-gray-500'>Высока вероятность угадывания.</p>*/}
      {/*  <div className='pt-2 flex'>*/}
      {/*    <ActionButtons type={ACTION_BUTTONS_TYPE.WARNING}>*/}
      {/*      Добавить ответы*/}
      {/*    </ActionButtons>*/}
      {/*    <ActionButtons type={ACTION_BUTTONS_TYPE.SUCCESS}>*/}
      {/*      Сгенерировать ответы*/}
      {/*    </ActionButtons>*/}
      {/*  </div>*/}
      {/*</StatisticsCard>*/}

      {/*<StatisticsCard cardTitle={t("idx_dif")}>*/}
      {/*  <p className='text-lg font-semibold'>ИД = -0,5</p>*/}
      {/*  <p className='text-gray-500'>*/}
      {/*    Отрицательной значения индекса свидетельствует о том, что слабые*/}
      {/*    испытуемые отвечают на данный вопрос лучше, чем сильные. Такие*/}
      {/*    тестовые задания должны отбраковываться. Фактически они уменьшают*/}
      {/*    точность всей процедуры тестирования.*/}
      {/*  </p>*/}
      {/*  <div className='pt-2'>*/}
      {/*    <ActionButtons type={ACTION_BUTTONS_TYPE.WARNING}>*/}
      {/*      Изменить вопрос*/}
      {/*    </ActionButtons>*/}
      {/*    <ActionButtons type={ACTION_BUTTONS_TYPE.DANGER}>*/}
      {/*      Удалить вопрос*/}
      {/*    </ActionButtons>*/}
      {/*  </div>*/}
      {/*</StatisticsCard>*/}
    </>
  );
};
