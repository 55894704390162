import React, { useCallback, useState } from "react";
import { Button, Form, Input, message } from "antd";
import {
  makeMinFormFieldRule,
  makeRequiredFormFieldRule,
} from "../../constants/rules";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import axios from "axios";
import { FormLabel } from "./styles";
import { useTranslation } from "react-i18next";
import {getBaseUrl} from "../../utils/getEnv";

interface RegistrationFormProps {
  onSuccess: () => void;
}

export const RegistrationForm = ({
  onSuccess,
}: RegistrationFormProps): JSX.Element => {
  const [emailError, setEmailError] = useState<string>();
  const [usernameError, setUserNameErrors] = useState<string>();

  const { t } = useTranslation();

  const onSubmit = useCallback(
    (values) => {
      const config = {
        email: values.email,
        password: values.password,
        username: values.username,
      };

      axios
        .post(
          `${getBaseUrl()}/v1.0/auth/registration/`,
          config
        )
        .then((data) => {
          if (data.data.success) {
            message.success(t("login_page.account_created"));
          }
        })
        .then(() => {
          onSuccess();
        })
        .catch((error) => {
          const errorData = error?.response?.data;
          if (errorData?.message?.email) {
            setEmailError(errorData?.message?.email[0].toString());
          }
          if (errorData?.message?.username) {
            setUserNameErrors(errorData?.message?.username[0].toString());
          }
        });
    },
    [onSuccess, t]
  );

  const validateMessages = {
    required: t("required_field"),
    types: {
      email: t("correct_email"),
      number: t("correct_number"),
    },
  };

  return (
    <Form
      name='normal_login'
      className='login-form'
      initialValues={{ remember: true }}
      onFinish={onSubmit}
      layout='vertical'
      requiredMark={false}
      validateMessages={validateMessages}
    >
      <Form.Item
        name='email'
        rules={[
          makeRequiredFormFieldRule(t("login_page.enter_email")),
          { type: "email" },
        ]}
        label={<FormLabel text={t("login_page.enter_email")} />}
        validateStatus={emailError ? "error" : undefined}
        help={emailError ? emailError : undefined}
      >
        <Input
          prefix={<UserOutlined className='site-form-item-icon' />}
          placeholder='example@stud.kpfu.ru'
          onChange={() => setEmailError("")}
        />
      </Form.Item>
      <Form.Item
        name='username'
        rules={[makeRequiredFormFieldRule(t("login_page.enter_username"))]}
        label={<FormLabel text={t("login_page.enter_username")} />}
        validateStatus={usernameError ? "error" : undefined}
        help={usernameError ? usernameError : undefined}
      >
        <Input
          prefix={<UserOutlined className='site-form-item-icon' />}
          placeholder={t("login_page.username")}
          onChange={() => setUserNameErrors("")}
        />
      </Form.Item>
      <Form.Item
        name='password'
        rules={[
          makeRequiredFormFieldRule(t("login_page.enter_password")),
          makeMinFormFieldRule(t("login_page.min_password_length")),
        ]}
        label={<FormLabel text={t("login_page.enter_password")} />}
      >
        <Input
          prefix={<LockOutlined className='site-form-item-icon' />}
          type='password'
          placeholder={t("login_page.password")}
        />
      </Form.Item>

      <Form.Item>
        <Button type='primary' htmlType='submit' className='login-form-button'>
          {t("login_page.create_account")}
        </Button>
      </Form.Item>
    </Form>
  );
};
