import React, { useCallback } from "react";
import { Routes } from "../../constants";
import { CardTestItem } from "../../pages";
import { useHistory } from "react-router-dom";
import { EditOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { antIconStyles, noop } from "../../utils";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

interface PlayButtonProps {
  item: CardTestItem;
}

export const PlayButton = ({ item }: PlayButtonProps): JSX.Element => {
  const history = useHistory();

  const { t } = useTranslation();
  const onStartClick = useCallback(() => {
    history.push(Routes.START_TEST_PREFIX + item.hash);
  }, [history, item]);

  return (
    <Button
      icon={<PlayCircleOutlined style={antIconStyles} />}
      type='default'
      onKeyDown={noop}
      onClick={onStartClick}
      className='border-0'
    >
      {t("run_test")}
    </Button>
  );
};

export const EditButton = ({ item }: PlayButtonProps): JSX.Element => {
  const history = useHistory();

  const { t } = useTranslation();
  const onClick = useCallback(() => {
    history.push(Routes.CONSTRUCTOR_PREFIX + item.hash);
  }, [history, item]);

  return (
    <Button
      icon={<EditOutlined style={antIconStyles} />}
      type='default'
      onKeyDown={noop}
      onClick={onClick}
      className='border-0'
    >
      {t("edit_test")}
    </Button>
  );
};
