export function moveInArray<T>(
  arr: Array<T>,
  fromIndex: number,
  toIndex: number
): Array<T> {
  const arrCopy = [...arr];
  const element = arrCopy[fromIndex];
  arrCopy.splice(fromIndex, 1);
  arrCopy.splice(toIndex, 0, element);
  return arrCopy;
}
